import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit {
  @Input() list: any[];

  @Output() shareCheckedList = new EventEmitter();
  // selectAll;
  // @Output() shareIndividualCheckedList = new EventEmitter();


  checkedList: any[];
  currentSelected: {};
  showDropDown;
  constructor() {
    this.checkedList = [];
  }
  ngOnInit(){
    this.list.unshift({name: 'Hammasi'});
  }
  selectAllFunc(selectAll){
    this.checkedList = [];
    if (selectAll) {
      this.list.map(l => {this.checkedList.push(l.name); });
    }
    this.list.map(a => {a.checked = selectAll; });
  }
  getSelectedValue(status: Boolean, value: String){
    if ((value === 'Hammasi')) {this.selectAllFunc(status); }
    if (this.checkedList[0] === 'Hammasi'){this.checkedList.splice(0, 1); this.checkedList.splice(this.checkedList.length - 1, 1); }
    if (status){
      this.checkedList.push(value);
    }else{
      this.list[0].checked = false;
      const index = this.checkedList.indexOf(value);
      this.checkedList.splice(index, 1);
    }

    this.currentSelected = {checked : status, name: value};

    // share checked list
    this.shareCheckedlist();

    // share individual selected item
    // this.shareIndividualStatus();
  }
  shareCheckedlist(){
    this.shareCheckedList.emit({selectedData: this.checkedList});
  }
  // shareIndividualStatus(){
  //     this.shareIndividualCheckedList.emit(this.currentSelected);
  // }



}
