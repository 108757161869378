import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { SkeletonComponent } from './skeleton.component';
import { DatePipe } from '@angular/common';
import { ButtonViewComponent } from './button-view/button-view.component';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';

interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-list-students',
  templateUrl: './list-students.component.html',
  styleUrls: ['./list-students.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class ListStudentsComponent implements OnInit {

  public announces_list = [];
  @Input() inputRoomRowData;
  @Output() closeAllStudents = new EventEmitter();
  dataSource: LocalDataSource = new LocalDataSource();
  loading = true;
  pager = 10;
  accommodateStudent = false;
  accommodateStudentData;
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    this.loading = true;
    this.getAnnounces();
    // this.genSkeleton();
    // this.dataSource.onChanged().subscribe(change => {
    //   switch (change.action) {
    //     case 'page':
    //       if (!this.dataSource['data'][((parseInt(change.paging.page) - 1) * this.pager)].title) {
    //         this.getAnnounces(parseInt(change.paging.page));
    //       }
    //       break;
    //   }
    // });
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    edit: {
      confirmDelete: true,
    },
    pager: {
      perPage: this.pager
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'left'
    },
    columns: {
      accommodate: {
        title: 'Talabani xonaga joylash',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowData => {
            this.accommodateStudentData = rowData
            this.accommodateStudent = true;
            // this.show_all_students = true;
          });
        }
      },
      full_name: {
        title: 'Ismi',
        editable: false
      },
      faculty: {
        title: 'Fakulteti',
      },
      course: {
        title: 'Bosqich',
      },
      group: {
        title: 'Guruh',
      },
      phone: {
        title: 'Telefon'
      },
      passport_series: {
        title: 'Pasport ma\'lumoti seriyasi va raqami',
      },
      passport_given_by: {
        title: 'Pasport ma\'lumoti kim tomonidan berilgan\n',
      },
      date_of_birth_new: {
        title: 'Tug\'ilgan yili',
      },
      permanent_address: {
        title: 'Doimiy yashash manzili',
        editable: false
      },
      current_address_dormitory: {
        title: 'Hozirda yashash joyi yotoqxona',
        editable: false
      },
      rental_address: {
        title: 'Hozirda yashash joyi ijara'
      },
      files_new: {
        title: 'Fayllar',
        type: 'html'
      },
      // inf_about_father: {
      //   title: 'Otasi haqida ma\'lumot'
      // },
      // inf_about_mother: {
      //   title: 'Onasi haqida ma\'lumot'
      // },
      // mfy_chairman: {
      //   title: 'MFY raisining I.F.Sh. va telefoni'
      // },
      // mfy_chairman_supervisor: {
      //   title: 'MFY noziriningI.F.Sh. va telefoni'
      // },
      // disadvantages: {
      //   title: 'Kamchiliklari'
      // }
    },
  };
  ngOnInit(){

  }
  closeAccommodateStudent(event){
    this.accommodateStudent = false;
    if(event.status === 'success'){
      this.closeAllStudents.emit({status: 'success', data: event.data});
    }
    // this.accommodateStudent = false;
  }
  getAnnounces(){
    this.httpService.getAllStudents()
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        this.genTable(result.students);
        console.log(result.students)
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
    });
  }

  // genSkeleton(){
  //       for (let i = 0; i < this.pager; i++) {this.announces_list.push({title: '', context: '', files: ''}); }
  //       this.dataSource.load(this.announces_list);
  //       this.loading = false;
  //       this.getAnnounces(1);
  // }
  genTable(students){
          students.forEach((res, idx) => {
          let a;
          res.not_changed = res;
          res.full_name = res.first_name + ' ' + res.last_name + ' ' + res.patronymic;
          //
          // res.all_phones = '<div><a href=tel:"' + res.phone + '">O‘zi: ' + res.phone + '</a></div>' +
          //                     '<div><a href=tel:"' + res.fathers_phone + '">Ota: ' + res.fathers_phone + '</a></div>' +
          //                     '<div><a href=tel:"' + res.mothers_phone + '">Ona: ' + res.mothers_phone + '</a></div>';
          //
          // res.address = '<div>doimiy: ' + res.permanent_address + '</div>' +
          //                     '<div>Yotoqxona: ' + res.dormitory_address + '</div>' +
          //                     '<div>Ijara: ' + res.rental_address + '</div>';
          res.date_of_birth_new = res.date_of_birth.day + '.' + res.date_of_birth.month + '.' + res.date_of_birth.year;
          res.permanent_address = res.permanent_address_region + ', ' + res.permanent_address_district + ', ' + res.permanent_address_street;
          if (res.dormitory_data && res.dormitory_data.length){
              res.current_address_dormitory = res.dormitory_data[res.dormitory_data.length - 1].building_number + ' - bino, '
              + res.dormitory_data[res.dormitory_data.length - 1].floor_number + ' - qavar, '
              + res.dormitory_data[res.dormitory_data.length - 1].room_number + ' - xona';
          }
          if (res.files.length){
            a = '<div class="file_download">';
            res.files.forEach((fet, indexFile) => {
                a += `<a href="${fet}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`;
              });
            a += '</div>';
            res.files_new = a;
          }
          // res.time = this.datePipe.transform( new Date(res.time * 1000) , 'dd/MM/yyyy hh:mm');
          this.dataSource['data'].push(res);
        });
          this.dataSource.refresh();
          this.loading = false;
  }
  onEditConfirm(event){
  }
  onDeleteConfirm(event){
    this.httpService.removeMyStudent(this.authService.user_data.id, event.data.time)
    .subscribe((result: any) => {
      if (result.status === 200) {
        alert('Tadbir muvaffaqiyatli ochirildi!');
        event.confirm.resolve();
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
}
