import { Component, OnInit } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { SkeletonComponent } from './skeleton.component';
import { DatePipe } from '@angular/common';
import { ButtonViewComponent } from './button-view/button-view.component';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class ListComponent implements OnInit {

  public room_list = [];
  dataSource: LocalDataSource = new LocalDataSource();
  loading = true;
  pager = 10;
  showAllStudents = false;
  showAllStudentsData;

  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    this.loading = true;
    this.getRooms();

  }
  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    pager: {
      perPage: this.pager
    },
    actions: {
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    columns: {
      add_student: {
        title: 'Talabani xonaga joylash',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowData => {
            this.showAllStudentsData = rowData;
            this.showAllStudents = true;
          });
        }
      },
      building_number: {
        title: 'Bino raqami',
      },
      floor_number: {
        title: 'Nechinchi qavat',
      },
      floor_gender: {
        title: 'Qavatda kimlar yashaydi',
      },
      room_number: {
        title: 'Xona raqami',
      },
      faculty: {
        title: 'Fakultet'
      },
      num_of_beds: {
        title: 'Xona sig‘imi',
      },
      available_beds: {
        title: 'Mavjud o‘rinlar',
      },
    },
  };
  ngOnInit(){

  }
  closeAllStudents(event){
    console.log(event);
    this.showAllStudents = false;
    if (event.status === 'success'){
      this.dataSource['data'].map(d => {
        if (d.id === event.data.accommodating_in_dormitory.id){
          d.available_beds --;
          if ( !d.accommodated_students){d.accommodated_students = []; };
          d.accommodated_students.push(event.data.accommodating_student_data);
        }
      });
      this.dataSource.refresh();
      alert('Talaba muvaffaqiyatli joylandi!');
    }
  }
  getRooms(){
    this.httpService.getDormitoryRooms()
    .subscribe(async (result: any ) => {
      if (result.status === 200) {
        this.genTable(result.rooms);
        console.log(result.rooms)
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
    });
  }
  // testtest(){
  //   console.log(this.dataSource)
  //   this.dataSource['data'][0].title = 'ssss';
  //   this.dataSource.refresh();
  // }

  genTable(rooms){
        rooms.map(r => {
          if (!r.accommodated_students){r.accommodated_students = []; };
          r.available_beds = r.num_of_beds - r.accommodated_students.length;
        });
        this.dataSource.load(rooms);
        // this.dataSource['data']=[];
        // this.dataSource.refresh();
        // this.dataSource.load(this.announces_list);
        this.loading = false;
  }
  onDeleteConfirm(event){
    // console.log('onDeleteConfirm', this.authService.user_data)
    this.httpService.removeDormitoryRoom(event.data)
    .subscribe((result: any ) => {
      if (result.status === 200) {
        alert('Xona muvaffaqiyatli ochirildi!');
        event.confirm.resolve();
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
    });
  }
}
