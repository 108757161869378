<!-- Container-fluid starts-->
<div class="container-fluid" [@fadeInAndOut]>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>E'lon qo‘shish</h5>
                </div>
                <div class="card-body">
                    <div class="needs-validation">
                        <div class="form-group row">
                            <label for="validationCustom0" class="col-xl-3 col-md-4"><span>*</span> Sarlavha</label>
                            <input class="form-control col-md-8" [(ngModel)]="title" id="validationCustom0" type="text" required="">
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom1" class="col-xl-3 col-md-4"><span>*</span> Mazmuni</label>
                            <textarea class="form-control col-md-8" [(ngModel)]="context" id="validationCustom1" required=""></textarea>
                        </div>
                        <ngx-dropzone
                            id="ngx-dropzone_4"
                            class="dropzone-custom dropzone dropzone-primary"
                            (change)="onSelectFile($event)"
                            accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemoveFile(f)">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div *ngIf="tmp.fileError" style="color:red;">{{tmp.fileError}}</div>
                        <button type="button" class="btn btn-primary" [disabled]="(!title)||(!context)" (click)="uploadFiles(0)">Saqlash</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<div class="lds-wrap" *ngIf="tmp.loadingFile">
    <circle-progress
      [percent]="tmp.progressOverAll + tmp.progress"
      [radius]="1.2"
      [outerStrokeWidth]="0.35"
      [innerStrokeWidth]="0.35"
      [space] = "-0.35"
      [outerStrokeColor]="'#007bff'"
      [innerStrokeColor]="'#e7e8ea'"
      [titleFontSize]= "0.5"
      [unitsFontSize]= "0.5"
      [showSubtitle] = "false"
      [animation]="true"
      [animationDuration]="300"
      [startFromZero]="false"
      [responsive]="true"
    ></circle-progress>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
