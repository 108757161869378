import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InputComponent } from "./atoms/input.component";
import { ButtonComponent } from "./atoms/button.component";
import { SelectComponent } from "./atoms/select.component";
import { DateComponent } from "./atoms/date.component";
import { RadiobuttonComponent } from "./atoms/radiobutton.component";
import { CheckboxComponent } from "./atoms/checkbox.component";
import { FileComponent } from "./atoms/file.component";
import { NumberComponent } from "./atoms/number.component";
import { InputAutoCompleteComponent } from "./atoms/inputautocomplete.component";
import { DynamicFieldDirective } from "./dynamic-field/dynamic-field.directive";
import { DynamicFormComponent } from "./dynamic-form.component";
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    NgxDatatableModule,
    NgbModule,
    AutocompleteLibModule
  ],
  declarations: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    DynamicFieldDirective,
    DynamicFormComponent,
    FileComponent,
    NumberComponent,
    InputAutoCompleteComponent
  ],
  bootstrap: [
    InputComponent
  ],
  exports: [
    DynamicFormComponent
  ],
  providers: [],
    entryComponents: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    FileComponent,
    NumberComponent
  ]
})
export class DynamicFormModule { }
