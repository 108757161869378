import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import {TutorResponseModule} from './tutor-response.module';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-event',
        component: ListComponent,
        data: {
          title: 'Javoblar ro‘yhati',
          breadcrumb: 'Tadbirlar ro‘yhati'
        }
      },
      {
        path: 'create-event',
        component: CreateComponent,
        data: {
          title: 'Ma\'lumot jo‘natish',
          breadcrumb: 'Ma\'lumot jo‘natish'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TutorResponseRoutingModule { }
