import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { TutorResponseRoutingModule } from './tutor-response-routing.module';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonComponent } from './list/skeleton.component';
@NgModule({
  declarations: [ListComponent, CreateComponent, SkeletonComponent],
  imports: [
    CommonModule,
    TutorResponseRoutingModule,
    NgxDatatableModule,
    FormsModule,
	  Ng2SmartTableModule,
    NgbModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot({}),
    ReactiveFormsModule
  ],
  providers: [NgbActiveModal],
  exports: [CreateComponent]
})
export class TutorResponseModule { }
