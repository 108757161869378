<!-- Container-fluid starts-->
<div class="container-fluid" [@fadeInAndOut]>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <h5>Talaba qo‘shish</h5>
                </div>
                <div class="card-body">
                      <form [formGroup]="addStudent" (ngSubmit)="uploadFiles(0)" class="needs-validation user-add" novalida>
                        <div class="form-group row">
                            <label for="validationCustom1" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Ismi
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="first_name"
                                    placeholder="Ismi"
                                    type="text"
                                    id="validationCustom1"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom2" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Familiyasi
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="last_name"
                                    placeholder="Familiyasi"
                                    type="text"
                                    id="validationCustom2"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom21" class="col-xl-3 col-md-4">
                                Sharifi
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="patronymic"
                                    placeholder="Sharifi"
                                    type="text"
                                    id="validationCustom21"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="faculty" class="col-xl-3 col-md-4">
                            <span>*</span>
                            Fakultet
                          </label>
                          <div class="col-md-8">
                            <select class="form-control digits" formControlName="faculty" id="faculty">
                              <option value="" selected value> -- tanlang -- </option>
                              <option *ngFor="let k of faculty;" [value]="k">{{k}}</option>
                            </select>
                          </div>
                        </div>

                        <div class="form-group row">
                          <label for="course" class="col-xl-3 col-md-4">
                            <span>*</span>
                            Bosqich
                          </label>
                          <div class="col-md-8">
                            <select class="form-control digits" formControlName="course" id="course">
                              <option value="" selected value> -- tanlang -- </option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="validationCustom292" class="col-xl-3 col-md-4">
                            <span>*</span>
                            Guruh
                          </label>
                          <div class="col-md-8">
                            <input
                              class="form-control"
                              formControlName="group"
                              placeholder="Guruh"
                              type="text"
                              id="validationCustom292"
                            >
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-md-4">
                            <span>*</span>
                            Pasport ma'lumoti
                          </label>
                          <div class="col-md-8">
                            <div>
                              <input
                                class="form-control"
                                formControlName="passport_series"
                                placeholder="Seriyasi va raqami"
                                type="text"
                                id="passport_series"
                              >
                            </div>
                            <div>
                              <input
                                class="form-control"
                                formControlName="passport_given_by"
                                placeholder="Kim tomonidan berilgan"
                                type="text"
                                id="passport_given_by"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" >
                          <label class="col-xl-3 col-md-4">
                            <span>*</span>
                            Tug'ilgan yili
                          </label>
                          <div class="col-md-8">
                            <div class="input-group input-grp-p">
                              <div class="input-group-append">
                                <button class="btn calendar" (click)="f.toggle()" type="button"><i
                                  class="fa fa-calendar"></i></button>
                              </div>
                              <input (click)="f.toggle()" class="datepicker-here form-control digits" ngbDatepicker
                                     formControlName="date_of_birth" #f="ngbDatepicker" readonly>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-xl-3 col-md-4">
                            <span>*</span>
                            Doimiy yashash manzili
                          </label>
                          <div class="col-md-8">
                            <select class="form-control digits" formControlName="permanent_address_region" >
                              <option value="" selected value> -- Viloyatni tanlang -- </option>
                              <option value="Andijon">Andijon</option>
                              <option value="Buxoro">Buxoro</option>
                              <option value="Fargʻona">Fargʻona</option>
                              <option value="Jizzax">Jizzax</option>
                              <option value="Qoraqalpogʻiston">Qoraqalpogʻiston</option>
                              <option value="Qashqadaryo">Qashqadaryo</option>
                              <option value="Xorazm">Xorazm</option>
                              <option value="Namangan">Namangan</option>
                              <option value="Navoiy">Navoiy</option>
                              <option value="Samarqand">Samarqand</option>
                              <option value="Surxondaryo">Surxondaryo</option>
                              <option value="Sirdaryo">Sirdaryo</option>
                              <option value="Toshkent">Toshkent</option>
                            </select>
                            <div>
                              <input
                                class="form-control"
                                formControlName="permanent_address_district"
                                placeholder="Tuman"
                                type="text"
                              >
                            </div>
                            <div>
                              <input
                                class="form-control"
                                formControlName="permanent_address_street"
                                placeholder="MFY, ko'cha, uy №"
                                type="text"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="validationCustom290" class="col-xl-3 col-md-4">
                            <span>*</span>
                            Telefon raqam
                          </label>
                          <div class="col-md-8">
                            <div>
                              <input
                                class="form-control"
                                formControlName="phone"
                                placeholder="Talaba"
                                type="text"
                                id="validationCustom290"
                              >
                            </div>
                            <div>
                              <input
                                class="form-control"
                                formControlName="fathers_phone"
                                placeholder="Otasi"
                                type="text"
                                id="validationCustom290"
                              >
                            </div>
                            <div>
                              <input
                                class="form-control"
                                formControlName="mothers_phone"
                                placeholder="Onasi"
                                type="text"
                                id="validationCustom290"
                              >
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-4">
                              Hozirda yashash joyi
                            </label>
                            <div class="col-md-8">
                                <div>
                                  <input
                                      class="form-control"
                                      formControlName="current_address_ttj"
                                      placeholder="TTJ"
                                      type="text"
                                  >
                                </div>
                                <div>
                                  <input
                                      class="form-control"
                                      formControlName="current_address_ttj_room"
                                      placeholder="Xonasi"
                                      type="text"
                                  >
                                </div>
                                <div>
                                  <input
                                      class="form-control"
                                      formControlName="rental_address"
                                      placeholder="Ijara"
                                      type="text"
                                  >
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                          <label for="inf_about_father" class="col-xl-3 col-md-4">
                            Otasi haqida ma'lumot
                          </label>
                          <div class="col-md-8">
                            <textarea
                              class="form-control"
                              formControlName="first_name"
                              placeholder="Otasi haqida ma'lumot"
                              type="text"
                              id="inf_about_father"
                            ></textarea>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="inf_about_mother" class="col-xl-3 col-md-4">
                            Onasi haqida ma'lumot
                          </label>
                          <div class="col-md-8">
                            <textarea
                              class="form-control"
                              formControlName="inf_about_mother"
                              placeholder="Onasi haqida ma'lumot"
                              type="text"
                              id="inf_about_mother"
                            ></textarea>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="mfy_chairman" class="col-xl-3 col-md-4">
                            MFY raisining I.F.Sh. va telefoni
                          </label>
                          <div class="col-md-8">
                            <input
                              class="form-control"
                              formControlName="mfy_chairman"
                              placeholder="MFY raisining I.F.Sh. va telefoni"
                              type="text"
                              id="mfy_chairman"
                            >
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="mfy_chairman_supervisor" class="col-xl-3 col-md-4">
                            MFY noziriningI.F.Sh. va telefoni
                          </label>
                          <div class="col-md-8">
                            <input
                              class="form-control"
                              formControlName="mfy_chairman_supervisor"
                              placeholder="MFY noziriningI.F.Sh. va telefoni"
                              type="text"
                              id="mfy_chairman_supervisor"
                            >
                          </div>
                        </div>
                        <div class="form-group row">
                          <label for="disadvantages" class="col-xl-3 col-md-4">
                            Kamchiliklari
                          </label>
                          <div class="col-md-8">
                            <input
                              class="form-control"
                              formControlName="disadvantages"
                              placeholder="Kamchiliklari"
                              type="text"
                              id="disadvantages"
                            >
                          </div>
                        </div>


                        <ngx-dropzone
                            id="ngx-dropzone_4"
                            class="dropzone-custom dropzone dropzone-primary"
                            (change)="onSelectFile($event)"
                            accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemoveFile(f)">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="pull-left">
                            <button type="submit" class="btn btn-primary" [disabled]="!addStudent.valid || loading">
                                Qo'shish<span *ngIf="loading">...</span>
                            </button>
                        </div>
                    </form>
<!--                     <div class="needs-validation">
                        <div *ngIf="tmp.fileError" style="color:red;">{{tmp.fileError}}</div>
                        <button type="button" class="btn btn-primary" [disabled]="(!title)||(!context)" (click)="uploadFiles(0)">Saqlash</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<div class="lds-wrap" *ngIf="tmp.loadingFile">
    <circle-progress
      [percent]="tmp.progressOverAll + tmp.progress"
      [radius]="1.2"
      [outerStrokeWidth]="0.35"
      [innerStrokeWidth]="0.35"
      [space] = "-0.35"
      [outerStrokeColor]="'#007bff'"
      [innerStrokeColor]="'#e7e8ea'"
      [titleFontSize]= "0.5"
      [unitsFontSize]= "0.5"
      [showSubtitle] = "false"
      [animation]="true"
      [animationDuration]="300"
      [startFromZero]="false"
      [responsive]="true"
    ></circle-progress>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
