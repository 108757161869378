import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RequestResponseComponent } from './request-response.component';
import { CompletedComponent } from '../admin-requests/completed/completed.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: RequestResponseComponent,
        data: {
          title: 'Topshiriqlar',
          breadcrumb: 'Topshiriqlar'
        }
      },
      {
        path: 'completed',
        component: CompletedComponent,
        data: {
          title: 'Bajarilgan Topshiriqlar ro‘yhati',
          breadcrumb: 'Bajarilgan Topshiriqlar ro‘yhati'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RequestResponseRoutingModule { }
