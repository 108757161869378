import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
@Component({
  selector: "app-radiobutton",
  template: `
	        <div class="form-group row" [formGroup]="group">
		        <label for="validationCustom{{random}}" class="col-xl-3 col-md-4">
		        	<span *ngFor="let validation of field.validations;">
		        		<span *ngIf="validation.name === 'required'">*</span>
		        	</span>
		        	{{field.label}}
		        </label>
		        <div class="col-md-8">
	                <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
	                    <label class="d-block" for="edo-ani{{random}}" *ngFor="let item of field.options; let i = index">
	                        <input class="radio_animated" [formControlName]="field.name" id="edo-ani{{i}}" type="radio" [value]="item">
	                        <label for="edo-ani{{i}}">{{item}}</label>
	                    </label>
	                </div>
		        </div>
		    </div>
`,
  styles: []
})
export class RadiobuttonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  random: number;
  constructor() {}
  ngOnInit() {this.random = Math.floor(Math.random() * 1000);}
}
