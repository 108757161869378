import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import firebase from 'firebase/app';
// import * as firebase from 'firebase/app';
import { from, Observable, of, BehaviorSubject } from 'rxjs';
import { take, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/shared/service/http.service';

export interface userInt{
  role: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Observable<any>;
  currentUser = new BehaviorSubject(null);
  user_data;
  // userR: userInt = {
  //   // role: "ADMIN",
  //   role: "PVO",
  //   email: 'test@gmail.com'
  // };
  // user$ = new BehaviorSubject<userInt>(this.userR);

  constructor(
    private afAuth: AngularFireAuth, 
    private db: AngularFirestore, 
    private router: Router,
    private httpService: HttpService
    ) {
    this.autoLog();
  }
  autoLog(){
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.httpService.signIn({uid:user.uid}).pipe(
            take(1),
            tap((data:any) => {
                  // data['id'] = user.uid;
                  // console.log('user ', data)
                  this.user_data = data;
                  this.currentUser.next(data);
            })
          );
        } else {
          this.currentUser.next(null);
          return of(null);
        }
      })
    );
  }
  signUp(credentials) {
    return this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password).then(data => {
      return this.httpService.signUp({
        uid: data.user.uid,
        first_name: credentials.first_name,
        last_name: credentials.last_name,
        patronymic: credentials.patronymic,
        // password: credentials.password,
        files: credentials.files,
        email: data.user.email,
        role: 'PVO',
        faculty: credentials.faculty, 
        phone: credentials.phone, 
        speciality: credentials.speciality,  
        groups: credentials.groups,
      }).subscribe(res=>{console.log('res signUp')},error=>{console.log('res error signUp')});
    });
  }
  
  recoverPass(code, password) {
    return this.afAuth.confirmPasswordReset(code, password);
  }
  
  signIn(credentials): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc(`users/${user.user.uid}`).valueChanges().pipe(
            take(1)
          );
        } else {
          return of(null);
        }
      })
    )
  }

  signOut() {
    return this.afAuth.signOut().then(() => {
      this.router.navigateByUrl('/auth/login');
    });
  }

  hasPermissions(permissions: string[]): boolean {
    for (const perm of permissions) {
      if (!this.currentUser.value || !this.currentUser.value.permissions.includes(perm)) {
        return false;
      }
    }
    return true;
  }

  resetPw(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }
}

