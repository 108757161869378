<!-- Container-fluid starts-->
<div class="container-fluid" [@fadeInAndOut]>
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
              <div class="card-header back_button_size space-between-r43" (click)="closeAccommodateStudent.emit({status: 'close'})">
                <div><h5>Talabani xonaga joylash</h5></div>
                <div class="close-tutor-work"><i class="fa fa-close"></i></div>
              </div>
                <div class="card-body">
                      <form [formGroup]="accommodateStudent" (ngSubmit)="uploadFiles(0)" class="needs-validation user-add" novalida>

                        <div class="form-group row" >
                          <label class="col-xl-3 col-md-4">
                            <span>*</span>
                            Boshlanish sanasi
                          </label>
                          <div class="col-md-8">
                            <div class="input-group input-grp-p">
                              <div class="input-group-append">
                                <button class="btn calendar" (click)="fStart.toggle()" type="button"><i
                                  class="fa fa-calendar"></i></button>
                              </div>
                              <input (click)="fStart.toggle()" class="datepicker-here form-control digits" ngbDatepicker
                                     formControlName="start_date" #fStart="ngbDatepicker" readonly>
                            </div>
                          </div>
                        </div>
                        <div class="form-group row" >
                          <label class="col-xl-3 col-md-4">
                            <span>*</span>
                            Tushash sanasi
                          </label>
                          <div class="col-md-8">
                            <div class="input-group input-grp-p">
                              <div class="input-group-append">
                                <button class="btn calendar" (click)="fFinish.toggle()" type="button"><i
                                  class="fa fa-calendar"></i></button>
                              </div>
                              <input (click)="fFinish.toggle()" class="datepicker-here form-control digits" ngbDatepicker
                                     formControlName="finish_date" #fFinish="ngbDatepicker" readonly>
                            </div>
                          </div>
                        </div>
                        <ngx-dropzone
                            id="ngx-dropzone_4"
                            class="dropzone-custom dropzone dropzone-primary"
                            (change)="onSelectFile($event)"
                            accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemoveFile(f)">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="pull-left">
                            <button type="submit" class="btn btn-primary" [disabled]="!accommodateStudent.valid || loading">
                                Qo'shish<span *ngIf="loading">...</span>
                            </button>
                        </div>
                    </form>
<!--                     <div class="needs-validation">
                        <div *ngIf="tmp.fileError" style="color:red;">{{tmp.fileError}}</div>
                        <button type="button" class="btn btn-primary" [disabled]="(!title)||(!context)" (click)="uploadFiles(0)">Saqlash</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
<div class="lds-wrap" *ngIf="tmp.loadingFile">
    <circle-progress
      [percent]="tmp.progressOverAll + tmp.progress"
      [radius]="1.2"
      [outerStrokeWidth]="0.35"
      [innerStrokeWidth]="0.35"
      [space] = "-0.35"
      [outerStrokeColor]="'#007bff'"
      [innerStrokeColor]="'#e7e8ea'"
      [titleFontSize]= "0.5"
      [unitsFontSize]= "0.5"
      [showSubtitle] = "false"
      [animation]="true"
      [animationDuration]="300"
      [startFromZero]="false"
      [responsive]="true"
    ></circle-progress>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
