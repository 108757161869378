<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="!loading" [@fadeInAndOut]>
    <div class="card">
        <div class="card-header">
            <h5>Xonalar</h5>
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <ng2-smart-table
                        [settings]="settings"
                        [source]="dataSource"
                        (deleteConfirm)="onDeleteConfirm($event)"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="loading">
    <div class="lds-dual-ring"></div>
</div>
<div class="show-all-students" *ngIf="showAllStudents">
  <ion-content>
    <app-list-students [inputRoomRowData]="showAllStudentsData" (closeAllStudents)="closeAllStudents($event)"></app-list-students>
  </ion-content>
</div>
<!-- Container-fluid Ends-->
