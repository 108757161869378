import { Component, OnInit } from '@angular/core';
// import { DataService } from 'src/app/shared/service/data.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DatePipe } from '@angular/common';
import { ButtonViewComponent } from './button-view/button-view.component';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-request-response',
  templateUrl: './request-response.component.html',
  styleUrls: ['./request-response.component.scss'],
  providers: [DatePipe],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class RequestResponseComponent implements OnInit {

  dataSource: LocalDataSource = new LocalDataSource();
  public announces_list = [];
  loading = true;
  kafdep_send_work = false;
  kafdep_send_data;
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    this.loading = true;
    this.getAdminRequests();
  }

  public settings = {
    delete: {
      confirmDelete: false,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      id: {
        title: 'Id',
      },
      title: {
        title: 'Sarlavha',
      },
      context: {
        title: 'Mazmuni'
      },
      timeShow: {
        title: 'Sana',
      },
      files: {
        title: 'Fayllar',
        type: 'html'
      },
      rejected_message: {
        title: 'Rad etilgan sababi',
      },
      link: {
        title: 'Tadbirlar',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowData => {

            this.kafdep_send_data = {
              request_file_name: rowData.time,
              tutor_uid: this.authService.user_data.uid,
              title: rowData.title,
              context: rowData.context,
              id: rowData.id
            };
            this.kafdep_send_work = true;
            // window.location.reload();
          });
        }
      },

    },
  };
  ngOnInit(){

  }
  close_create_event(event){
    this.kafdep_send_work = false;
    if (event.id){
      this.dataSource['data'].map(res => {
        if (res.id === event.id){
          res.tutors.map(t => {if (t.uid === event.tutor_uid){t.status = 'pending'; } });
        }
      });
      this.dataSource.refresh();
    }
  }
  getAdminRequests(){
    this.httpService.getAdminRequests()
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        this.genTable(result.admin_requests);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
  genTable(adminRequests){
      adminRequests.sort((a: any, b: any) => {
          return (b.time - a.time) ? (b.time - a.time) : (a.time - b.time);
        });
      adminRequests.forEach(res => {
          let tutor;
          if (
            (tutor = res.tutors.find(t => {
               if (t.uid === this.authService.user_data.uid){return t; }else{return 0; }
            }))) {
              let a;
              if (res.files.length){
                 // res.files = 'fayllar';
                  a = '<div class="file_download">';
                  res.files.forEach((fet, indexFile) => {
                    a += `<a href="${fet}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`;
                  });
                  a += '</div>';
                  res.files = a;
              }
              if (tutor && tutor.message && (tutor.status === 'start' || tutor.status === 'rejected')){
                res.rejected_message = tutor.message[tutor.message.length - 1];
              }
              res.timeShow = this.datePipe.transform( new Date(res.time * 1000) , 'dd/MM/yyyy hh:mm');
              this.announces_list.push(res);
          }
        });
        this.dataSource.load(this.announces_list);
        this.loading = false;
  }
  onDeleteConfirm(event){
    this.httpService.removeAnnounce(event.data.id)
    .subscribe((result: any) => {
      if (result.status === 200) {
        alert('E\'lon muvaffaqiyatli ochirildi!');
        event.confirm.resolve();
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
}
