import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-create-announce',
  templateUrl: './create-announce.component.html',
  styleUrls: ['./create-announce.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class CreateAnnounceComponent implements OnInit {

  title:string;
  context:string;
  tmp = {
    error: "",
    loading: false,
    fileError: '',
    progress: 0,
    progressOverAll: 0,
    loadingFile: false,
  };
  files:File[] = [];
  // progress: number = 0;

  constructor(private httpService: HttpService) { }
  onSelectFile(event) {
      this.tmp.fileError = '';
      if (event.rejectedFiles[0]) {
        if (event.rejectedFiles[0].reason==='type') {
           this.tmp.fileError = 'Fayl turlarini yuklash mumkin: jpeg, png, jpg, doc, pdf';
           return;
        }
        if (event.rejectedFiles[0].reason==='size') {
           this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
           return;
        }
      }
      this.files.push(...event.addedFiles);
      // this.accountForm.controls[type].setValue(this.files[type]);
  }
  onRemoveFile(event) {
      this.files.splice(this.files.indexOf(event), 1);
      // this.accountForm.controls[type].setValue(this.files[type]);
  }
  ngOnInit() {
    this.tmp.progressOverAll = 0;
  }
  uploadFileUrl = [];
  uploadFiles(nth){
    if(!this.files.length){this.createAnnounce();return;}
    this.tmp.loadingFile = true;
    const formData = new FormData();
    formData.append('avatar', this.files[nth]);
    this.httpService.uploadFile(formData)
    .subscribe((event) => {
      switch (event.type) {
        case HttpEventType.Sent:
          break;
        case HttpEventType.ResponseHeader:
          // console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          var eventTotal = event.total ? event.total : 0;
          this.tmp.progress = (Math.round(event.loaded / eventTotal * 100)) / this.files.length;
          // console.log(`Uploaded! ${this.tmp.progress}%`);
          break;
        case HttpEventType.Response:
          this.uploadFileUrl.push(event.body.url);
          if((nth+1)===this.files.length){this.createAnnounce();}
          else{this.uploadFiles( nth+1 );}
          this.tmp.progressOverAll += (this.tmp.progress);
          this.tmp.progress = 0;
      }
    })
  }
  createAnnounce(){
    this.tmp.loadingFile = false;
    this.tmp.loading = true;
    this.httpService.createAnnounce({title:this.title, context:this.context, files: this.uploadFileUrl})
    .subscribe(async(result:any)=>{
      if (result.status === 200) {
        alert('E\'lon muvaffaqiyatli qo‘shildi!');
        this.title = '';
        this.context = '';
        this.files = [];
        this.uploadFileUrl = [];
        this.tmp.loading = false;
        // console.log(new Date((result.time)*1000));
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error =>{
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
// {
//   form: FormGroup;
//   uploadResponse;

//   constructor(private formBuilder: FormBuilder, private httpService: HttpService) { }

//   ngOnInit() {
//     this.form = this.formBuilder.group({
//       avatar: ['']
//     });
//   }

//   onFileSelect(event) {
//     if (event.target.files.length > 0) {
//       const file = event.target.files[0];
//       this.form.get('avatar').setValue(file);
//     }
//   }

//   onSubmit() {
//     const formData = new FormData();
//     formData.append('avatar', this.form.get('avatar').value);

//     this.httpService.uploadFile(formData).subscribe(
//       (res) => {
//         this.uploadResponse = res;
//           console.log(res);
//       },
//       (err) => {
//         console.log(err);
//       }
//     );
//   }

// }
}
