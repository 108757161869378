import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'

@Component({
  selector: 'skeleton-component',
  template: `
    <div class="skeleton_wrapper" *ngIf="!value">
      <div class="table_skeleton">
        <div class="loading">
          <div class="bar"></div>
        </div>
      </div>
    </div>
    <div *ngIf="value">{{value}}</div>  
  `,
  styles: [`
      .none{
        display:block;
      }
      @keyframes loading {
         40% {
           background-position: 100% 0;
        }
         100% {
           background-position: 100% 0;
        }
      }
      .skeleton_wrapper {
         width: 100%;
         display:flex;
         justify-content:center;
      }
       .skeleton_wrapper .table_skeleton {
         margin: 0 auto;
         text-align: left;
         border-spacing: 0;
      }
       .skeleton_wrapper .table_skeleton {
         padding: 20px 0;
         min-width: 100px;
      }
       .skeleton_wrapper .loading {
         position: relative;
      }
       .skeleton_wrapper .loading .bar {
         background-color: #c9c9c9;
         height: 14px;
         border-radius: 7px;
         width: 200px;
      }
       .skeleton_wrapper .loading:after {
         position: absolute;
         transform: translateY(-50%);
         top: 50%;
         left: 0;
         content: "";
         display: block;
         width: 100%;
         height: 24px;
         background-image: linear-gradient(100deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0) 80%);
         background-size: 200px 24px;
         background-position: -100px 0;
         background-repeat: no-repeat;
         animation: loading 1s infinite;
      }
  `]
})
export class SkeletonComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;
  // @Output() save: EventEmitter<any> = new EventEmitter();
  // @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router){}

  ngOnInit() {
    // console.log()
  }

  // navigateToTutorWorks() {
  //   this.save.emit(this.rowData);
  //   // console.log(this.rowData)
  //   // this.router.navigate(['tutor-works/'+this.rowData.uid]);
  //   //   const url = this.router.serializeUrl(
  //   //   this.router.createUrlTree([`/tutor-works/${this.rowData.uid}`])
  //   // );

  //   // window.open(url, '_blank');
  // }
}