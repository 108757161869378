import {Component, Output, EventEmitter, ElementRef, OnInit} from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { map  } from 'rxjs/operators';
interface tmp{
	indexId: string;
	tableLoading: boolean;
  fieldTitle?: string;
}
@Component({
    selector: 'kafdep-component',
    template: `
    	<div *ngFor="let tutor of rowData.tutors">
    		{{tutor.tutor_name}}
    		<i *ngIf="tutor.status === 'start' || tutor.status === 'rejected'" title="Tyutorlar bo‘limi tomonidan ma'lumot jo‘natilishi kutilmoqda" class="fa fa-question red" aria-hidden="true"></i>
    		<i *ngIf="tutor.status === 'pending'" title="Tyutorlar bo‘limi tomonidan ma'lumot jo‘natilgan va tekshirilishi kutilmoqda" class="fa fa-hourglass-start" aria-hidden="true"></i>
    		<i *ngIf="tutor.status === 'completed'" title="Tyutorlar bo‘limi tomonidan ma'lumot jo‘natilgan va qabul qilingan" class="fa fa-check green" aria-hidden="true"></i>
    	</div>
    `,
    styles: [`
      .fa{
        font-size: 20px;
      }
    	.red{
    		color:red;
    	}
    	.yellow{
    		color: yellow;
    	}
    	.green{
    		color: #3ceb3c;
    	}
    `]
})
export class KafdepCompletedComponent implements OnInit {

    rowData: any;
    showSimilars:boolean = false;

    @Output() save: EventEmitter<any> = new EventEmitter();

    constructor(
   		private dataService: DataService,
   		private httpService: HttpService,
   		private el: ElementRef
   	){

    }
    ngOnInit(){
    }


}
 function replaceAll(str, from, to){
    var n = '';
    for(let g of str){
      if (g === from) {
        g = to;
      }
      n += g;
    }
    return n;
  }
