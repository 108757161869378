import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: ListComponent,
        data: {
          title: 'Yotoqxona xonalari ro‘yhati',
          breadcrumb: 'Yotoqxona xonalari ro‘yhati'
        }
      },
      {
        path: 'create',
        component: CreateComponent,
        data: {
          title: 'Xona qo‘shish',
          breadcrumb: 'Xona qo‘shish'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DormitoryRoomRoutingModule { }
