import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../interface/field.interface";
import { InputComponent } from "../atoms/input.component";
import { ButtonComponent } from "../atoms/button.component";
import { SelectComponent } from "../atoms/select.component";
import { DateComponent } from "../atoms/date.component";
import { RadiobuttonComponent } from "../atoms/radiobutton.component";
import { CheckboxComponent } from "../atoms/checkbox.component";
import { FileComponent } from "../atoms/file.component";
import { NumberComponent } from "../atoms/number.component";
import { InputAutoCompleteComponent } from "../atoms/inputautocomplete.component";

const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  date: DateComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  file: FileComponent,
  number: NumberComponent,
  inputautocomplete: InputAutoCompleteComponent
};
@Directive({
  selector: "[dynamicField]"
})
export class DynamicFieldDirective implements OnInit {
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  componentRef: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) {}
  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.type]
    );
    this.componentRef = this.container.createComponent(factory);
    this.componentRef.instance.field = this.field;
    this.componentRef.instance.group = this.group;
  }
}
