import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/shared/service/data.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { GroupCellComponent, GroupCellEditorComponent } from './group-cell.component';
import { ButtonViewComponent } from './button-view/button-view.component'
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';

interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class ListUserComponent implements OnInit {
  public user_list = []
  show_tutor_work = false;
  show_tutor_data = 'all';

  tmp: tmp = {loading: true};
  constructor(
    private dataService: DataService,
    private httpService: HttpService
  ) {
    this.getUsers();
  }

  public settings = {
    edit: {
      editButtonContent: 'EDIT ',
      saveButtonContent: 'SAVE ',
      cancelButtonContent: 'CANCEL ',
      confirmSave: true,
    },
    actions: {
      add: false,
      edit: true,
      delete: false,
      position: 'right'
    },
    columns: {
      full_name: {
        title: 'Ism',
        editable: false
      },
      faculty: {
        title: 'Fakultet',
        editable: true
      },
      phone: {
        title: 'Telefon',
        editable: true
      },
      speciality: {
        title: 'Mutaxassislik',
        editable: true
      },
      groups_gen: {
        title: 'Gruppalar',
        type: 'custom',
        editable: false,
        renderComponent: GroupCellComponent
      },
      email: {
        title: 'Pochta',
        editable: false
      },
      link: {
        title: "Tadbirlar",
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowData => {
            // console.log('row 444', rowData.uid);
            this.show_tutor_data = rowData;
            this.show_tutor_work = true;
          });
        }
      },
    },
  };
  ngOnInit() {
  }
  getUsers(){
    this.httpService.getUsers()
    .subscribe(async(result:any)=>{
      if (result.status === 200) {
        this.genTable(result.users);
        // this.genTable(result.announces);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error =>{
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
  genTable(users){
    users.map(user=>{
      var groups_gen = '';
      user.groups.map((item, idx)=>{
        groups_gen += ((idx)?(', '):(''))+item.group;
      })
      user['full_name'] = user.last_name + ' ' + user.first_name + ' ' + ((user.patronymic)?(user.patronymic):(''));
      user['groups_gen'] = groups_gen;
    })
    this.user_list = users;
    this.tmp.loading = false;

  }
  onEditConfirm(event){
    this.httpService.updateUserGroups(event.newData)
    .subscribe((res:any)=>{
      if(res.status===200){
        alert('Gruppalar mufavvaqiyatli yangilandi!!!');
      }else{
        alert('Grupplarni yangilar olmadim yana bir bor urinib ko‘ring!');
        window.location.reload();
      }
    },error=>{
      alert('Grupplarni yangilar olmadim yana bir bor urinib ko‘ring! Hatolik: ' + JSON.stringify(error));
        window.location.reload();
    })
    event.confirm.resolve();

  }
  // onDeleteConfirm(event){
  //   this.httpService.removePvo(event.data.added_id)
  //   .subscribe((result:any)=>{
  //     if (result.status === 200) {
  //       alert('Foydalanuvchi muvaffaqiyatli ochirildi!');
  //       event.confirm.resolve();
  //     }else{
  //       alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
  //     }
  //   }, error =>{
  //     alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
  //     console.error(error);
  //   });
  // }
}

