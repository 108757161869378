import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RequestResponseRoutingModule } from './request-response-routing.module';
import { RequestResponseComponent } from './request-response.component';
import { SharedModule } from '../../shared/shared.module';
import { OrderByPipe } from './order-by.pipe';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TutorResponseModule } from '../tutor-response/tutor-response.module';
import { ButtonViewComponent } from './button-view/button-view.component';
import { CompletedComponent } from './completed/completed.component';
import { KafdepCompletedComponent } from './completed/kafdep-completed.component';
import { CompletedButtonViewComponent } from './completed/completed-button-view/completed-button-view.component';
import { CheckedResponsesComponent } from './checked-responses/checked-responses.component';

@NgModule({
  declarations: [
    RequestResponseComponent,
    OrderByPipe,
    ButtonViewComponent,
    CompletedComponent,
    KafdepCompletedComponent,
    CompletedButtonViewComponent,
    CheckedResponsesComponent
  ],
  imports: [
    CommonModule,
    RequestResponseRoutingModule,
    SharedModule,
    FormsModule,
    Ng2SmartTableModule,
    TutorResponseModule,
    IonicModule.forRoot()
  ]
})
export class RequestResponseModule { }
