import { Component, OnInit } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { SkeletonComponent } from './skeleton.component';
import { DatePipe } from '@angular/common';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';

interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class ListComponent implements OnInit {

  public requestsList = [];
  dataSource: LocalDataSource = new LocalDataSource();
  loading = true;
  pager = 10;

  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    this.loading = true;
    this.genSkeleton();
    this.dataSource.onChanged().subscribe(change => {
      switch (change.action) {
        case 'page':
          if (!this.dataSource['data'][((parseInt(change.paging.page) - 1) * this.pager)].title) {
            this.getAnnounces(parseInt(change.paging.page));
          }
          // this.pageChange(change.paging.page);
          break;
      }
    });
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    pager: {
      perPage: this.pager
    },
    actions: {
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    columns: {
      title: {
        title: 'Sarlavha',
        type: 'custom',
        renderComponent: SkeletonComponent
      },
      context: {
        title: 'Mazmuni',
        type: 'custom',
        renderComponent: SkeletonComponent
      },
      createdAt: {
        title: 'Sana',
      },
      files: {
        title: 'Fayllar',
        type: 'html'
      }
    },
  };
  ngOnInit(){

  }
  getAnnounces(page){
    this.httpService.getMyEvents(this.authService.user_data.id, page, this.pager)
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        this.genTable(result.events, page, result.length);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
    });
  }
  // testtest(){
  //   console.log(this.dataSource)
  //   this.dataSource['data'][0].title = 'ssss';
  //   this.dataSource.refresh();
  // }
  genSkeleton(){
        for (let i = 0; i < this.pager; i++) {this.requestsList.push({title: '', context: '', files: ''}); }
        this.dataSource.load(this.requestsList);
        this.loading = false;
        this.getAnnounces(1);
  }
  genTable(announces, page, length){
        if (page === 1){this.dataSource['data'] = []; for (let i = 0; i < length; i++){this.dataSource['data'].push({title: '', context: '', files: ''}); }}

        announces.forEach((res, idx ) => {
          let a;
          if (res.files.length){
             // res.files = 'fayllar';
              a = '<div class="file_download">';
              res.files.forEach((fet, indexFile) => {
                a += `<a href="${fet}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`;
              });
              a += '</div>';
              res.files = a;
          }
          res.createdAt = this.datePipe.transform( new Date(res.time * 1000) , 'dd/MM/yyyy hh:mm');
          this.dataSource['data'][((page - 1) * this.pager) + idx] = res;
        });
        // this.dataSource['data']=[];
        this.dataSource.refresh();
        // this.dataSource.load(this.requestsList);
        this.loading = false;
  }
  onDeleteConfirm(event){
    // console.log('onDeleteConfirm', this.authService.user_data)
    this.httpService.removeMyEvents(this.authService.user_data.id, event.data.time)
    .subscribe((result: any) => {
      if (result.status === 200) {
        alert('Tadbir muvaffaqiyatli ochirildi!');
        event.confirm.resolve();
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
}
