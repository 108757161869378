import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'list-button-view',
  template: `
    <div class="d-flex flex-nowrap text-white center">
          <button type="button" title="E‘lon bo‘yicha javoblarni ko‘rish." class="btn btn-info" (click)="goToPage()">
            <i class="fa fa-arrow-right"></i>
          </button>
    </div>
  `,
  styles: [`
    .center{
      justify-content:center;
    }
    .btn{
      padding: 3px 0 0 0px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  `]
})
export class CompletedButtonViewComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
    public authService: AuthService,
  ){}

  ngOnInit() {
  }

  goToPage() {
    this.save.emit(this.rowData);
  }
}
