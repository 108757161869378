import { Routes } from '@angular/router';

export const contentPvo: Routes = [
  {
    path: 'announce',
    loadChildren: () => import('../../components/announce/announce.module').then(m => m.AnnounceModule),
    data: {
      breadcrumb: 'E\'lonlar'
    }
  },
  {
    path: 'request-response',
    loadChildren: () => import('../../components/request-response/request-response.module').then(m => m.RequestResponseModule),
    data: {
      breadcrumb: 'Topshiriqlar'
    }
  },
  {
    path: 'tutor-event',
    loadChildren: () => import('../../components/tutor-event/tutor-event.module').then(m => m.TutorEventModule),
    data: {
      breadcrumb: 'Tadbirlar'
    }
  },
  {
    path: 'students',
    loadChildren: () => import('../../components/tutor-students/tutor-students.module').then(m => m.TutorStudentsModule),
    data: {
      breadcrumb: 'Talabalar'
    }
  },
];

export const contentAdmin: Routes = [
  {
    path: 'users',
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: 'Foydalanuvchilar'
    }
  },
  {
    path: 'statistics',
    loadChildren: () => import('../../components/statistics/statistics.module').then(m => m.StatisticsModule),
    data: {
      breadcrumb: 'Statistika'
    }
  },
  {
    path: 'admin-requests',
    loadChildren: () => import('../../components/admin-requests/admin-requests.module').then(m => m.AdminRequestsModule),
    data: {
      breadcrumb: 'Tyutor admin'
    }
  },
  {
    path: 'tutor-admin',
    loadChildren: () => import('../../components/tutor-admin/tutor-admin.module').then(m => m.TutorAdminModule),
    data: {
      breadcrumb: 'Tyutor admin'
    }
  },
  {
    path: 'tutor-works/:id',
    loadChildren: () => import('../../components/tutor-works/tutor-works.module').then(m => m.TutorWorksModule),
    data: {
      breadcrumb: 'Tyutor qilingan ishlari'
    }
  },
  {
    path: 'a-students',
    loadChildren: () => import('../../components/tutor-students/tutor-students.module').then(m => m.TutorStudentsModule),
    data: {
      breadcrumb: 'Talabalar'
    }
  },
];

export const contentTtj: Routes = [
  {
    path: 'dormitory-room',
    loadChildren: () => import('../../components/dormitory-room/dormitory-room.module').then(m => m.DormitoryRoomModule),
    data: {
      breadcrumb: 'Yotoqxona'
    }
  },
  {
    path: 'accommodated-students',
    loadChildren: () => import('../../components/accommodated-students/accommodated-students.module').then(m => m.AccommodatedStudentsModule),
    data: {
      breadcrumb: 'Yotoqxona yashovchi talablar'
    }
  }
];
