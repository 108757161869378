import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FieldConfig, FieldData, PvoKafInter, Chart } from '../interface/field.interface';
import { environment } from 'src/environments/environment.prod';

interface RatingInt{
  chartFakul?: Chart;
  chartKaf?: Chart;
  kafedraSet?: any;
  kafedra?: any;
  fakultetSet?: any;
  fakultet?: any;
  settings?: any;
  source?: any;
}
@Injectable({
  providedIn: 'root'
})
export class DataService {
  tutors: any[];
  field: FieldData[] = [];
  fieldsInformLoaded = false;
  fieldsInform: any;
  fieldsInform$ = new BehaviorSubject<any>(null);

  field$ = new BehaviorSubject<FieldData[]>(this.field);
  // checkField$ = new BehaviorSubject<any>({data: null});
  // checkFieldTable$ = new BehaviorSubject<any>({data: null});
  checkField$ = new BehaviorSubject<any>(null);
  checkFieldLength$ = new BehaviorSubject<number>(0);
  fieldsRejected$ = new BehaviorSubject<number>(0);
  // pvoKaf: PvoKafInter[] = [];
  pvoKaf$ = new BehaviorSubject<PvoKafInter[]>([]);

  updateCitationData = new BehaviorSubject<any>(null);
  updateCitationStatus = false;


  studetnData = null;
  // _tizim = [
  //   {
  //     fakultet: "Mexanika - mashinasozlik",
  //     kafedralar: [
  //        "Mashinasozlik texnologiyasi va avtomatlashtirish",
  //        "Oliy matematika",
  //        "Tadbiqiy mexanika",
  //        "Texnologik mashinalar va jihozlar",
  //        "Yer usti transport tizimlari va ularni ekspluatatsiyasi",
  //     ]
  //   },
  //   {
  //     fakultet: "Energetika",
  //     kafedralar: [
  //       "Elektr energetika",
  //       "Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari",
  //       "Fizika",
  //     ]
  //   },
  //   {
  //     fakultet: "Kimyo texnologiya",
  //     kafedralar: [
  //       "Hayot faoliyati xavfsizligi",
  //       "Jismoniy tarbiya",
  //       "Kimyoviy texnologiya",
  //       "Oziq-ovqatlar texnologiyasi",
  //       "Qishloq xo'jalik mahsulotlarini saqlash va ularni qayta ishlash texnologiya",
  //       "Umumiy kimyo",
  //     ]
  //   },
  //   {
  //     fakultet: "Qurilish",
  //     kafedralar: [
  //       "Bino va inshootlar qurilishi",
  //       "Muhandislik kommunikatsiyalari qurilishi va montaji",
  //       "O'zbekiston tarixi va Ijtimoiy fanlar",
  //       "Geodeziya, kartografiya va kadastr",
  //     ]
  //   },
  //   {
  //     fakultet: "Ishlab chiqarishda boshqaruv",
  //     kafedralar: [
  //       "Iqtisodiyot",
  //       "Menejment",
  //       "Tillarni o'rgatish",
  //       "Buxgalteriya xisobi va audit",
  //     ]
  //   },
  //   {
  //     fakultet: "Yengil sanoat va to'qimachilik",
  //     kafedralar: [
  //       "Yengil sanoat texnologiyalari va jixozlari",
  //       "O'zbek tili va adabiyoti",
  //       "Tabiiy tolalar",
  //     ]
  //   },
  //   {
  //     fakultet: "Arxitektura va qurilish materiallari",
  //     kafedralar: [
  //       "Arxitektura",
  //       "Qurilish materiallari buyumlari va konstruksiyalari ishlab chiqish",
  //       "Chizma geometriya va muhandislik grafikasi",
  //     ]
  //   },
  //   {
  //     fakultet: "Kompyuterlashgan loyihalash tizimlari",
  //     kafedralar: [
  //       "Elektronika va asbobsozlik",
  //       "Intellektual muhandislik tizimlari",
  //       "Metrologiya standartlashtirish va maxsulot sifati menejmenti",
  //     ]
  //   }
  // ]
  // bazadan olinishi mumkin bolgan ozgaruvchilar
  chanAbFormulas: any;
  rating$ = new BehaviorSubject<RatingInt>(null);
  get tizim(){
    return environment.tizim;
  }

  constructor() {
    this.chanAbFormulas = {
      '3d2': {
        grastud: environment.grastud
      }
    };
  }
}


