import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DashboardModule } from './components/dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './components/pages/pages.module';
import { MenusModule } from './components/menus/menus.module';
import { UsersModule } from './components/users/users.module';
import { SettingModule } from './components/setting/setting.module';
import { AuthModule } from './components/auth/auth.module';
import { DormitoryRoomModule } from './components/dormitory-room/dormitory-room.module';
import { DormitoryAccommodationModule } from './components/dormitory_accommodation/dormitory_accommodation.module';
import { AccommodatedStudentsModule } from './components/accommodated-students/accommodated-students.module';
import { TutorStudentsModule } from './components/tutor-students/tutor-students.module';
import { TutorAdminModule } from './components/tutor-admin/tutor-admin.module';
import { RequestResponseModule } from './components/request-response/request-response.module';
import { StatisticsModule } from './components/statistics/statistics.module';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestore } from '@angular/fire/firestore';
import { SimpleModalModule } from 'ngx-simple-modal';

import { HttpClientModule } from '@angular/common/http';

import { environment } from './../environments/environment.prod';
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import { IonicModule } from '@ionic/angular';
// import { MatSliderModule } from '@angular/material/slider';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DashboardModule,
    DormitoryRoomModule,
    DormitoryAccommodationModule,
    AccommodatedStudentsModule,
    SettingModule,
    AuthModule,
    SharedModule,
    PagesModule,
    MenusModule,
    UsersModule,
    TutorStudentsModule,
    TutorAdminModule,
    RequestResponseModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    SimpleModalModule.forRoot({container: 'modal-container'}),
    HttpClientModule,
    IonicModule.forRoot(),
    StatisticsModule
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {
    constructor(){
      firebase.initializeApp(environment.firebaseConfig);
      firebase.database();
      firebase.storage();
    }
}
