import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'


@Component({
  selector: 'kick-out',
  template: `
    <div class="d-flex flex-nowrap text-white center">
      <button type="button" class="btn btn-info" (click)="navigateTo()">
        <i class="fa fa-trash"></i>
      </button>
    </div>
  `,
  styles: [`
    .color-black{
      color: #000;
    }
    .center{
      justify-content:center;
    }
    .btn{
      font-size: 12px;
      padding: 0px 0 0 1px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  `]
})
export class KickOutComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router){}

  ngOnInit() {
  }

  navigateTo() {
    this.save.emit(this.rowData);
  }
}
