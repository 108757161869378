import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
// import { DataService } from 'src/app/shared/service/data.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { SkeletonComponent } from './skeleton.component';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-tutor-works',
  templateUrl: './tutor-works.component.html',
  styleUrls: ['./tutor-works.component.scss'],
  providers: [DatePipe],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class TutorWorksComponent implements OnInit {

  @Input() tutor_data:any;
  @Output() close_tutor_work = new EventEmitter();

  public announces_list = []
  dataSource: LocalDataSource = new LocalDataSource();
  pager = 10;
  loading = true;
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    // private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  public settings = {
    delete: {
      confirmDelete: false,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      // avatar: {
      //   title: 'Avatar',
      //   type: 'html'
      // },
      tutor_name: {
        title: 'Tyutor',
      },
      title: {
        title: 'Sarlavha',
        type: 'custom',
        renderComponent: SkeletonComponent
      },
      context: {
        title: 'Mazmuni',
        type: 'custom',
        renderComponent: SkeletonComponent
      },
      time: {
        title: 'Sana',
      },
      files: {
        title: 'Fayllar',
        type: 'html'
      }
    },
  };
  ngOnInit(){
    this.loading = true;
    this.genSkeleton();
    this.dataSource.onChanged().subscribe(change => {
      switch (change.action) {
        case 'page':
          if (!this.dataSource['data'][((parseInt(change.paging.page) - 1)*this.pager)].title) {
            this.getAnnounces('none' , parseInt(change.paging.page));
          }
          // this.pageChange(change.paging.page);
          break;
      }
    });
  }
  genSkeleton(){
        for (var i = 0; i < this.pager; i++) {this.announces_list.push({title: '', context: '', files: ''});}
        this.dataSource.load(this.announces_list);
        this.loading = false;
        if(this.tutor_data && this.tutor_data.uid !== 'all'){
            this.getAnnounces(this.tutor_data.uid, 1);
        }else{
            this.getAnnounces('none', 1);
        }
  }
  getAnnounces(uid, page){
    if(uid === 'none')uid='';
    this.httpService.getMyEvents(uid, page, this.pager)
    .subscribe(async(result:any)=>{
      if (result.status === 200) {
        console.log('result', result)
        this.genTable(result.events, page, result.length);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error =>{
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
  genTable(announces, page, length){
        if(page === 1){this.dataSource['data']=[];for(var i=0; i<length; i++){this.dataSource['data'].push({title: '', context: '', files: ''});}}
        // announces.sort((a:any, b:any) =>{
        //   return (b.time - a.time)?(b.time - a.time):(a.time - b.time);
        // })
        announces.forEach((res,idx)=>{
          var a;
          if(res.files&&res.files.length){
              a = '<div class="file_download">';
              res.files.forEach((fet, indexFile)=>{
                a += `<a href="${fet}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
              })
              a += '</div>'
              res.files = a;
          }
          res.time = this.datePipe.transform( new Date(res.time * 1000) , 'dd/MM/yyyy hh:mm');
          this.dataSource['data'][((page - 1)*this.pager) + idx] = res;
          // this.announces_list.push(res);
        })
        this.dataSource.refresh();
        this.loading = false;
  }
  // onDeleteConfirm(event){
  //   // console.log('onDeleteConfirm', this.authService.user_data)
  //   this.httpService.removeMyEvents(this.authService.user_data.id, event.data.id)
  //   .subscribe((result:any)=>{
  //     if (result.status === 200) {
  //       alert('Tadbir muvaffaqiyatli ochirildi!');
  //       event.confirm.resolve();
  //     }else{
  //       alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
  //     }
  //   }, error =>{
  //     alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
  //     console.error(error);
  //   });
  // }
}
