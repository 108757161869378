import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'

@Component({
  selector: 'button-view',
  template: `
    <div class="d-flex flex-nowrap text-white center">
      <button type="button" *ngIf="(rowData.accommodated_students)&&((rowData.num_of_beds - rowData.accommodated_students.length) > 0)" class="btn btn-info" (click)="navigateTo()">
        <i class="fa fa-arrow-right"></i>
      </button>
      <div class="color-black" *ngIf="((rowData.num_of_beds - rowData.accommodated_students.length) === 0)">Xona to‘lgan</div>
    </div>
  `,
  styles: [`
    .center{
      justify-content:center;
    }
    .color-black{
      color: black;
    }
    .btn{
      font-size: 12px;
      padding: 0px 0 0 1px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  `]
})
export class ButtonViewComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;
  // @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(private router: Router){}

  ngOnInit() {
  }

  navigateTo() {
    this.save.emit(this.rowData);
    // console.log(this.rowData)
    // this.router.navigate(['tutor-works/'+this.rowData.uid]);
    //   const url = this.router.serializeUrl(
    //   this.router.createUrlTree([`/tutor-works/${this.rowData.uid}`])
    // );

    // window.open(url, '_blank');
  }
}
