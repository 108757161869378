import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { Ng2SmartTableModule } from 'ng2-smart-table';
import { UsersRoutingModule } from './users-routing.module';
import { ListUserComponent } from './list-user/list-user.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { RegisterComponent } from './register/register.component';
import { FormsModule } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GroupCellComponent, GroupCellEditorComponent} from './list-user/group-cell.component';
import { TutorWorksComponent } from '../tutor-works/tutor-works.component';
import { IonicModule } from '@ionic/angular';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
  declarations: [
  	ListUserComponent,
  	CreateUserComponent,
  	RegisterComponent,
  	GroupCellComponent,
  	GroupCellEditorComponent,
  	TutorWorksComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    UsersRoutingModule,
    FormsModule,
    NgxDropzoneModule,
    NgCircleProgressModule,
    IonicModule.forRoot()
  ]
})
export class UsersModule { }
