
<div>
  <div (mouseleave)="showDropDown = false">
    <button class="drop-toggle flat" (click)="showDropDown=!showDropDown">
      <span *ngIf="checkedList.length<0 || checkedList.length + 1 !== list.length "> -- tanlang -- </span>
      <span *ngIf="checkedList.length + 1 === list.length">Hammasi</span>
      <!-- <span *ngIf="checkedList.length<=0"> -- tanlang -- </span> -->
      <!-- <span *ngIf="checkedList.length>0">{{checkedList.join(', ')}}</span> -->
      <i class="fa fa-angle-down"></i>
    </button>
    <div class="drop-show" *ngIf="showDropDown">
      <!--             <label *ngFor="let a of list">
                      <input type="checkbox" [(ngModel)]="a.checked"
                          (change)="getSelectedValue(a.checked,a.name)" />

                      <span>{{a.name}}</span>
                  </label> -->
      <!--             <div class="card">
                      <div class="card-body">
                          <div class="needs-validation">
                              <div class="form-group row">
                                  <div class="checkbox checkbox-primary" >
                                      <input id="checkbox-primary-267"  type="checkbox" [(ngModel)]="selectAll" (change)="selectAllFunc($event)">
                                      <label for="checkbox-primary-276" >Hammasi</label>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div> -->
      <div class="card">
        <div class="card-body">
          <div class="needs-validation">
            <div class="form-group row" *ngFor="let a of list; let idx = index">
              <div class="checkbox checkbox-primary" >
                <input id="checkbox-primary-2{{idx}}"  type="checkbox" [(ngModel)]="a.checked" (change)="getSelectedValue(a.checked,a.name)">
                <label for="checkbox-primary-2{{idx}}" >{{a.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <app-multi-select-dropdown [list]="list" [selectedData]="selectedData" (shareCheckedList)="shareCheckedList($event)"
  (shareIndividualCheckedList)="shareIndividualCheckedList($event)">
</app-multi-select-dropdown>
https://stackblitz.com/edit/multiselect-checkbox-dropdown-cnqt3u?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.module.ts
-->
