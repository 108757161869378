import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Router } from '@angular/router';

import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AuthService } from 'src/app/shared/service/auth.service';
import { HttpService } from 'src/app/shared/service/http.service';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'app-register-pvo',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class RegisterComponent implements OnInit {

  @ViewChild('tabSet', {static: false}) tabSet;
  public registerForm: FormGroup;

  tmp = {
    error: "",
    loading: false,
    fileError: '',
    progress: 0,
    progressOverAll: 0,
    loadingFile: false,
  };
  files:File[] = [];
  loading: boolean = false;
  groups: FormArray;
  environment;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private httpService: HttpService,
    private formBuilder: FormBuilder
  ) {
    this.createRegisterForm();
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      group: '',
    });
  }
  ngOnInit() {
    this.environment = environment;
    this.tmp.progressOverAll = 0;
  }
  addItem(): void {
    this.groups = this.registerForm.get('groups') as FormArray;
    this.groups.push(this.createItem());
  }
  onSelectFile(event) {
      this.tmp.fileError = '';
      if (event.rejectedFiles[0]) {
        if (event.rejectedFiles[0].reason==='type') {
           this.tmp.fileError = 'Fayl turlarini yuklash mumkin: jpeg, png, jpg, doc, pdf';
           return;
        }
        if (event.rejectedFiles[0].reason==='size') {
           this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
           return;
        }
      }
      this.files.push(...event.addedFiles);
  }
  onRemoveFile(event) {
      this.files.splice(this.files.indexOf(event), 1);
  }
  uploadFileUrl = [];
  uploadFiles(nth){
    if(!this.files.length){this.register();return;}
    this.tmp.loadingFile = true;
    const formData = new FormData();
    formData.append('avatar', this.files[nth]);
    this.httpService.uploadFile(formData)
    .subscribe((event) => {
      switch (event.type) {
        case HttpEventType.Sent:
          // console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          // console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          var eventTotal = event.total ? event.total : 0;
          this.tmp.progress = (Math.round(event.loaded / eventTotal * 100)) / this.files.length;
          // console.log(`Uploaded! ${this.tmp.progress}%`);
          break;
        case HttpEventType.Response:
          this.uploadFileUrl.push(event.body.url);
          if((nth+1)===this.files.length){this.register();}
          else{this.uploadFiles( nth+1 );}
          this.tmp.progressOverAll += (this.tmp.progress);
          this.tmp.progress = 0;
      }
    })
  }
  async register() {
    // this.groups.patchValue({uid: this.authService.user_data.id})
    this.registerForm.value.files = this.uploadFileUrl;
    // console.log('group value ', this.registerForm.value);
    // console.log('addStudent e', this.addStudent.value, this.uploadFileUrl);
    this.tmp.loadingFile = false;
    this.tmp.loading = true;
    this.auth.signUp(this.registerForm.value).then(res => {
        this.loading = false;
        this.tmp.loading = false;
        this.registerForm.reset();
        alert("Foydalanuvchi muvaffaqiyatli qo'shildi!")
        this.auth.signOut();
    }, async err => {
        this.loading = false;
        alert(err.message);
    });
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      first_name: ['', Validators.required],
      faculty: ['', Validators.required],
      phone: ['', Validators.required],
      speciality: ['', Validators.required],
      groups: new FormArray([]),
      last_name: ['', Validators.required],
      patronymic: ['']
    });
    this.addItem();
  }

  onSubmit() {

  }

}
