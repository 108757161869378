<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="!tmp.loading" [@fadeInAndOut]>
    <div class="card">
        <div class="card-header">
            <h5>Foydalanuvchilar ma'lumotlari</h5>
        </div>
        <div class="card-body">
<!--             <div class="btn-popup pull-right">
                <a class="btn btn-primary" [routerLink]="'/users/register-new'">Foydalanuvchi yaratish</a>
            </div> -->
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <ng2-smart-table
                        [settings]="settings"
                        [source]="user_list"
                        (editConfirm)="onEditConfirm($event)"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
<div class="tutor-works-fixed" *ngIf="show_tutor_work">
    <ion-content>
        <app-tutor-works [tutor_data]="show_tutor_data" (close_tutor_work)="show_tutor_work = false"></app-tutor-works>
    </ion-content>
</div>
