import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListStudentsComponent } from './list-students/list-students.component';
import { AccommodateStudentComponent } from './accommodation/accommodate-student.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-students',
        component: ListStudentsComponent,
        data: {
          title: 'Talabalar ro‘yhati',
          breadcrumb: 'Talabalar ro‘yhati'
        }
      },
      {
        path: 'add-student',
        component: AccommodateStudentComponent,
        data: {
          title: 'Yotoqxonaga talaba joylash',
          breadcrumb: 'Yotoqxonaga talaba joylash'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DormitoryAccommodationRoutingModule { }
