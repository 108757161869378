import { Component, EventEmitter, Output } from '@angular/core';
import { DefaultEditor, ViewCell } from 'ng2-smart-table';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';

@Component({
  template: `
      <div class="user_group_wrap" *ngIf="!loading">
        <div class="user_group_display-flex" *ngFor="let item of rowData.groups; let idx = index;">
          <div>{{item.group}}</div>
          <div class="user_group_icon" (click)="trash_group(idx)"><i class="fa fa-trash"></i></div>
          <div *ngIf="rowData.groups.length !== idx+1 "> , </div>
        </div>
        <div class="user_group_align-items">
            <div>
              <input class="user_group_input" [(ngModel)]="new_group_name"/>
            </div>
            <div class="user_group_icon" (click)="addGroup()"><i class="fa fa-plus"></i></div>  
        </div>
      </div>

  `,
  styles: [`
    .user_group_wrap{
      display:flex; 
      flex-direction:column;
      align-items:center;
    }
    .user_group_display-flex{
      display:flex
    }
    .user_group_align-items{
      display:flex; 
      align-items:center;
    }
    .user_group_input{
      height:30px; 
      width:80%;
    }
    .user_group_icon{
      padding-left:5px; 
      cursor:pointer;
    }
  `]
})
export class GroupCellComponent implements ViewCell {
  value;
  rowData;
  new_group_name;
  loading = false;
  constructor(
    private httpService:HttpService, 
    private dataService:DataService
  ){}
  
  trash_group(idx){
    delete this.rowData.groups_gen;
    this.rowData.groups.splice(idx, 1);
    this.updateUserGroups();
  }
  addGroup(){
    if(!this.new_group_name){return;}
    this.rowData.groups.push({group: this.new_group_name})
    this.new_group_name = '';
    this.updateUserGroups();
  }
  updateUserGroups(){
    this.httpService.updateUserGroups(this.rowData)
    .subscribe((res:any)=>{      
      console.log(res)
      if(res.status===200){
        alert('Gruppalar mufavvaqiyatli yangilandi!!!');
      }else{
        alert('Grupplarni yangilar olmadim yana bir bor urinib ko‘ring!');
        window.location.reload();
      }
    },error=>{
      alert('Grupplarni yangilar olmadim yana bir bor urinib ko‘ring! Hatolik: ' + JSON.stringify(error));
        window.location.reload();
    })
  }
}

@Component({
    template: `
      <div>
        <div>
          <select (change)="onSelectChange()"><option>1</option><option>2</option></select>
        </div>  
        <div>
          <input [(ngModel)]="test" (change)="onInputChange()"/>
        </div>  
      </div>
    `
})
export class GroupCellEditorComponent extends DefaultEditor {
    // rowData: any;
    test = 'sss1';
   @Output() save: EventEmitter<any> = new EventEmitter();

    onSelectChange(){
    }
    onInputChange() {
        // this.rowData.total = this.rowData.amount * this.rowData.price;
        // this.save.emit(this.rowData);
    }
}

