import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class CreateComponent implements OnInit {
  // progress: number = 0;

  constructor(
      private httpService: HttpService,
      private authService: AuthService
    ) {}

  @Input() event_data: any;
  @Output() close_event = new EventEmitter();

  title: string;
  context: string;
  tmp = {
    error: '',
    loading: false,
    fileError: '',
    progress: 0,
    progressOverAll: 0,
    loadingFile: false,
  };
  files: File[] = [];
  uploadFileUrl = [];
  onSelectFile(event) {
      this.tmp.fileError = '';
      if (event.rejectedFiles[0]) {
        if (event.rejectedFiles[0].reason === 'type') {
           this.tmp.fileError = 'Fayl turlarini yuklash mumkin: jpeg, png, jpg, doc, pdf';
           return;
        }
        if (event.rejectedFiles[0].reason === 'size') {
           this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
           return;
        }
      }
      this.files.push(...event.addedFiles);
      // this.accountForm.controls[type].setValue(this.files[type]);
  }
  onRemoveFile(event) {
      this.files.splice(this.files.indexOf(event), 1);
      // this.accountForm.controls[type].setValue(this.files[type]);
  }
  ngOnInit() {
    console.log('event_data', this.event_data);
    this.tmp.progressOverAll = 0;
  }
  uploadFiles(nth){
    if (!this.files.length){this.createAnnounce(); return; }
    this.tmp.loadingFile = true;
    const formData = new FormData();
    formData.append('avatar', this.files[nth]);
    this.httpService.uploadFile(formData)
    .subscribe((event) => {
      switch (event.type) {
        case HttpEventType.Sent:
          // console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          // console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          let eventTotal = event.total ? event.total : 0;
          this.tmp.progress = (Math.round(event.loaded / eventTotal * 100)) / this.files.length;
          // console.log(`Uploaded! ${this.tmp.progress}%`);
          break;
        case HttpEventType.Response:
          this.uploadFileUrl.push(event.body.url);
          if ((nth + 1) === this.files.length){this.createAnnounce(); }
          else{this.uploadFiles( nth + 1 ); }
          this.tmp.progressOverAll += (this.tmp.progress);
          this.tmp.progress = 0;
      }
    });
  }
  createAnnounce(){
    this.tmp.loadingFile = false;
    this.tmp.loading = true;
    this.httpService.tutorResponse(
      {
        request_file_name: this.event_data.request_file_name,
        tutor_uid: this.event_data.tutor_uid,
        title: this.title,
        context: this.context,
        files: this.uploadFileUrl,
        uid: this.authService.user_data.id,
        id: this.event_data.id
      }
    )
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        console.log('answer to announce', result);
        alert('Topshiriqga javob muvaffaqiyatli jo‘natildi!');
        this.title = '';
        this.context = '';
        this.files = [];
        this.uploadFileUrl = [];
        this.tmp.loading = false;
        this.close_event.emit(this.event_data);
      }else{
        this.close_event.emit('true');
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      this.close_event.emit('true');
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
}
