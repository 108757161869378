<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="!loading" [@fadeInAndOut]>
    <div class="card">
        <div *ngIf="tutor_data" class="card-header back_button_size space-between-r43" (click)="close_tutor_work.emit('true')">
            <div>{{tutor_data.last_name}} {{tutor_data.first_name}} {{tutor_data.patronymic}}</div>
            <div class="close-tutor-work"><i class="fa fa-close"></i></div>
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <ng2-smart-table
                        [settings]="settings"
                        [source]="dataSource"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="loading">
    <div class="lds-dual-ring"></div>
</div>
<!-- Container-fluid Ends-->
