import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListStudentsComponent } from './list-students/list-students.component';
import { CreateStudentComponent } from './create-student/create-student.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-students',
        component: ListStudentsComponent,
        data: {
          title: "Talabalar ro‘yhati",
          breadcrumb: "Talabalar ro‘yhati"
        }
      },
      {
        path: 'create-student',
        component: CreateStudentComponent,
        data: {
          title: "Talaba qo‘shish",
          breadcrumb: "Talaba qo‘shish"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TutorStudentsRoutingModule { }
