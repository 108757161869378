<div class="container-fluid" [@fadeInAndOut]>
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Yangi Foydalanuvchi</h5>
                </div>
                <div class="card-body tab2-card">
                    <form [formGroup]="registerForm" (ngSubmit)="uploadFiles(0)" class="needs-validation user-add" novalida>
                        <div class="form-group row">
                            <label for="validationCustom1" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Ismi
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="first_name"
                                    placeholder="Ismi"
                                    type="text"
                                    id="validationCustom1"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom2" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Familiyasi
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="last_name"
                                    placeholder="Familiyasi"
                                    type="text"
                                    id="validationCustom2"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom21" class="col-xl-3 col-md-4">
                                Sharifi
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="patronymic"
                                    placeholder="Sharifi"
                                    type="text"
                                    id="validationCustom21"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom28d6" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Fakultet
                            </label>
                            <div class="col-md-8">
                                <select class="form-control digits" formControlName="faculty" id="validationCustom28d6">
                                    <option value="" selected value> -- tanlang -- </option>
                                    <option *ngFor="let t of environment.tizim" [value]="t.fakultet">{{t.fakultet}}</option>
                                </select>
                            </div>
                        </div>
<!--                         <div class="form-group row">
                            <label for="validationCustom286" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Fakultet
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="faculty"
                                    placeholder="Fakultet"
                                    type="text"
                                    id="validationCustom286"
                                >
                            </div>
                        </div> -->
                        <div class="form-group row">
                            <label for="validationCustom290" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Telefon
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="phone"
                                    placeholder="Telefon"
                                    type="text"
                                    id="validationCustom290"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom292" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Mutaxassislik
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="speciality"
                                    placeholder="Mutaxassislik"
                                    type="text"
                                    id="validationCustom292"
                                >
                            </div>
                        </div>
                        <div formArrayName="groups">
                            <div class="form-group row" *ngFor="let item of registerForm.get('groups')['controls']; let i = index;">
                                <label for="validationCustom21222" class="col-xl-3 col-md-4 color-black">
                                    <span *ngIf="i===0">Gruppalar</span>
                                </label>
                                <div class="col-md-8">
                                    <div [formGroupName]="i">
                                        <input
                                            class="form-control"
                                            formControlName="group"
                                            placeholder="Biriktirilgan gruppalar"
                                            type="text"
                                            id="validationCustom21222"
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom21" class="col-xl-3 col-md-4">
                            </label>
                            <div class="col-md-8">
                                    <div (click)="addItem()"><i class="fa fa-plus"></i></div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom3" class="col-xl-3 col-md-4">
                                <span>*</span>
                                email
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="email"
                                    placeholder="email"
                                    type="email"
                                    id="validationCustom3"
                                >
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="validationCustom4" class="col-xl-3 col-md-4">
                                <span>*</span>
                                Parol
                            </label>
                            <div class="col-md-8">
                                <input
                                    class="form-control"
                                    formControlName="password"
                                    placeholder="parol"
                                    type="password"
                                    id="validationCustom4"
                                >
                            </div>
                        </div>
                        <ngx-dropzone
                            id="ngx-dropzone_4"
                            class="dropzone-custom dropzone dropzone-primary"
                            (change)="onSelectFile($event)"
                            accept="image/jpeg,image/jpg,image/png,application/pdf,application/msword"
                            >
                            <ngx-dropzone-label>
                                <div class="dz-message needsclick">
                                    <i class="fa fa-cloud-upload"></i>
                                    <h4 class="mb-0 f-w-600">Fayllarni bu yerga tashlang yoki yuklash uchun bosing.</h4>
                                </div>
                            </ngx-dropzone-label>
                            <ngx-dropzone-preview *ngFor="let f of files;" [removable]="true" (removed)="onRemoveFile(f)">
                                <ngx-dropzone-label style="width: 160px; height: 120px; overflow:hidden;">{{ f.name }}</ngx-dropzone-label>
                            </ngx-dropzone-preview>
                        </ngx-dropzone>
                        <div class="pull-left">
                            <button type="submit" class="btn btn-primary" [disabled]="!registerForm.valid || loading">
                                Qo'shish<span *ngIf="loading">...</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loadingFile">
    <circle-progress
      [percent]="tmp.progressOverAll + tmp.progress"
      [radius]="1.2"
      [outerStrokeWidth]="0.35"
      [innerStrokeWidth]="0.35"
      [space] = "-0.35"
      [outerStrokeColor]="'#007bff'"
      [innerStrokeColor]="'#e7e8ea'"
      [titleFontSize]= "0.5"
      [unitsFontSize]= "0.5"
      [showSubtitle] = "false"
      [animation]="true"
      [animationDuration]="300"
      [startFromZero]="false"
      [responsive]="true"
    ></circle-progress>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
    <div class="lds-dual-ring"></div>
</div>
