import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from "./windows.service";
import { IndexFormsService } from './index-forms.service'; 
import { DataService } from './data.service'; 
import { AuthService } from './auth.service'; 
// Menu
export interface Menu {
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
	indexId?: string;
	status?: string;
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	public screenWidth: any
	public collapseSidebar: boolean = false

	constructor(
		@Inject(WINDOW) private window,
		private indexFormsService: IndexFormsService,
		private dataService: DataService,
		private authService: AuthService
	) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true
		}
		this.authService.user.subscribe(user=>{
			if (user) {
				switch (user.role) {
					case "ADMIN":
						this.getMenuItemsAdmin();
						break;
					case "PVO":
						this.getMenuItemsPvo();
						// code...
						break;
					default:
						// code...
						break;
				}
			}
		})
	}

	// Windows width
	@HostListener("window:resize", ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}
	getMenuItemsPvo(){
		this.MENUITEMS = [
			// {
			// 	title: 'Uy', path: '/home', icon: 'home', type: 'link', active: false
			// },
			// {
			// 	title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
			// },
			{
				title: 'E\'lonlar', path: '/announce', icon: 'twitch', type: 'link', active: false
			},
			// {
			// 	title: 'Tekshirilayotgan', path: '/being-checked', icon: 'help-circle', type: 'link', active: false
			// },
			// {
			// 	title: 'Rad etilgan', path: '/rejected-field', icon: 'thumbs-down', type: 'link', active: false
			// },
			{
				title: 'Tadbir', icon: 'twitch', type: 'sub', active: false, children: [
					{ path: '/tutor-event/list-event', title: 'q‘oshish', type: 'link' },
					{ path: '/tutor-event/create-event', title: 'ro‘yhati', type: 'link' },
				]
			},
			// {
			// 	title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
			// 		{ path: '/rating/fakul-table', title: 'Fakultet', type: 'link' },
			// 		{ path: '/rating/kaf-table', title: 'Kafedra', type: 'link' },
			// 		{ path: '/rating/teachers', title: 'O\'qituvchilar', type: 'link' },
			// 	]
			// }
		]
		this.dataService.field$.subscribe(fields=>{
			this.MENUITEMS = [
				// {
				// 	title: 'Uy', path: '/home', icon: 'home', type: 'link', active: false
				// },
				// {
				// 	title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
				// },
				{
					title: 'E\'lonlar', path: '/announce', icon: 'twitch', type: 'link', active: false
				},
				// {
				// 	title: 'Tekshirilayotgan', path: '/being-checked', icon: 'help-circle', type: 'link', active: false
				// },
				// {
				// 	title: 'Rad etilgan', path: '/rejected-field', icon: 'thumbs-down', type: 'link', active: false
				// },
				{
					title: 'Tadbir', icon: 'twitch', type: 'sub', active: false, children: [
						{ path: '/tutor-event/list-event', title: 'ro‘yhati', type: 'link' },
						{ path: '/tutor-event/create-event', title: 'q‘oshish', type: 'link' },
					]
				},
				// {
				// 	title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
				// 		{ path: '/rating/fakul-table', title: 'Fakultet', type: 'link' },
				// 		{ path: '/rating/kaf-table', title: 'Kafedra', type: 'link' },
				// 		{ path: '/rating/teachers', title: 'O\'qituvchilar', type: 'link' },
				// 	]
				// }
			]
			// fields.forEach(field=>{
			// 	if (field) {
			// 		this.MENUITEMS[1].children.push({title: replaceAll((field.id), "d", "."), path: `/indexes-data-pvo/${field.id}`, type: 'link'})
			// 		this.MENUITEMS.push({title: replaceAll((field.id), "d", "."), path: `/indexes/${field.id}`, icon: 'clipboard', type: 'link', active: false, status: (field.status && field.status.title === 'closed')?'closed':'open'})
			// 	}
			// })
			this.items.next(this.MENUITEMS);
		})
		this.items.next(this.MENUITEMS);
	}
	getMenuItemsAdmin(){
		this.MENUITEMS = [
			{
				title: 'E‘lon', icon: 'twitch', type: 'sub', active: false, children: [
					{ path: '/announce-admin/list-announce', title: 'q‘oshish', type: 'link' },
					{ path: '/announce-admin/create-announce', title: 'ro‘yhati', type: 'link' },
				]
			},
			{
				title: 'Foydalanuvchilar', icon: 'user-plus', type: 'sub', active: false, children: [
					{ path: '/users/list-user', title: 'ro\'yxati', type: 'link' },
					// { path: '/users/create-user', title: 'yaratish', type: 'link' },
					{ path: '/users/register-new', title: 'Yangi foydalanuvchi', type: 'link' },
				]
			},
			// {
			// 	title: 'Uy', path: '/home-admin', icon: 'home', type: 'link', active: false
			// },
			// {
			// 	title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
			// },
			// {
			// 	title: 'Tekshirish Kaf', icon: 'check-square', type: 'sub', active: false, children: []
			// },
			// {
			// 	title: 'Tekshirish', path: '/check-ind', icon: 'check-square', type: 'link', active: false
			// },
			// {
			// 	title: 'Yaratish', path: '/create-index', icon: 'file-plus', type: 'link', active: false
			// },
			// {
			// 	title: 'Indikatorlar', icon: 'file-plus', type: 'sub', active: false, children: [
			// 		{ path: '/create-index', title: 'yaratish', type: 'link' },
			// 		{ path: '/ctrl-indexes', title: 'boshqarish', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
			// 		{ path: '/ratinga/fakul-table', title: 'Fakultet', type: 'link' },
			// 		{ path: '/ratinga/kaf-table', title: 'Kafedra', type: 'link' },
			// 		{ path: '/ratinga/teachers', title: 'O\'qituvchilar', type: 'link' },
			// 		{ path: '/ratinga/statistics', title: 'Statistika', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
			// 		{ path: 'http://ferpi.tsnqb.uz/2021/', title: '2021', type: 'extTabLink' },
			// 	]
			// }
		]
		// this.dataService.tizim.forEach(res=>{
		// 	res.kafedralar.forEach(kaf=>{
		// 		this.MENUITEMS[2].children.push({title: kaf, path: `/check-ind-bkaf/${kaf}`, type: 'link'})
		// 	})
		// })
		this.dataService.field$.subscribe(fields=>{
			this.MENUITEMS = [
				{
					title: 'E‘lon', icon: 'twitch', type: 'sub', active: false, children: [
						{ path: '/announce-admin/list-announce', title: 'ro‘yhati', type: 'link' },
						{ path: '/announce-admin/create-announce', title: 'q‘oshish', type: 'link' },
					]
				},
				{
					title: 'Foydalanuvchilar', icon: 'user-plus', type: 'sub', active: false, children: [
						{ path: '/users/list-user', title: 'ro\'yxati', type: 'link' },
						// { path: '/users/create-user', title: 'yaratish', type: 'link' },
						{ path: '/users/register-new', title: 'Yangi foydalanuvchi', type: 'link' },
					]
				},
				// {
				// 	title: 'Uy', path: '/home-admin', icon: 'home', type: 'link', active: false
				// },
				// {
				// 	title: 'Ma\'lumotlar', icon: 'file-text', type: 'sub', active: false, children: []
				// },
				// {
				// 	title: 'Tekshirish Kaf', icon: 'check-square', type: 'sub', active: false, children: []
				// },
				// {
				// 	title: 'Tekshirish', path: '/check-ind', icon: 'check-square', type: 'link', active: false
				// },
				// {
				// 	title: 'Yaratish', path: '/create-index', icon: 'file-plus', type: 'link', active: false
				// },
				// {
				// 	title: 'Indikator', icon: 'file-plus', type: 'sub', active: false, children: [
				// 		{ path: '/create-index', title: 'yaratish', type: 'link' },
				// 		{ path: '/ctrl-indexes', title: 'boshqarish', type: 'link' },
				// 	]
				// },
				// {
				// 	title: 'Baholash', icon: 'sidebar', type: 'sub', active: false, children: [
				// 		{ path: '/ratinga/fakul-table', title: 'Fakultet', type: 'link' },
				// 		{ path: '/ratinga/kaf-table', title: 'Kafedra', type: 'link' },
				// 		{ path: '/ratinga/teachers', title: 'O\'qituvchilar', type: 'link' },
				// 		{ path: '/ratinga/statistics', title: 'Statistika', type: 'link' },
				// 	]
				// },
				// {
				// 	title: 'Tarix', icon: 'clock', type: 'sub', active: false, children: [
				// 		{ path: 'http://ferpi.tsnqb.uz/2021/', title: '2021', type: 'extTabLink' },
				// 	]
				// }
			]
			// this.dataService.tizim.forEach(res=>{
			// 	res.kafedralar.forEach(kaf=>{
			// 		this.MENUITEMS[2].children.push({title: kaf, path: `/check-ind-bkaf/${kaf}`, type: 'link'})
			// 	})
			// })
			// fields.forEach(field=>{
			// 	if (field) {
			// 		this.MENUITEMS[1].children.push({title: replaceAll((field.id), "d", "."), path: `/indexes-data/${field.id}`, type: 'link'})
			// 	}
			// })
			this.items.next(this.MENUITEMS);
		})
		this.items.next(this.MENUITEMS);
	}

	MENUITEMS: Menu[] = [
		// {
		// 	title: 'create-index', path: '/create-index', icon: 'clipboard', type: 'link', active: false
		// },
		// {
		// 	title: '1.1', path: '/indexes/1d1', icon: 'clipboard', type: 'link', active: false, 
		// },
		// {
		// 	title: '1.2', path: '/indexes/1d2', icon: 'clipboard', type: 'link', active: false, 
		// },
			// {
			// 	path: '/dashboard/default', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
			// },
			// {
			// 	title: 'Products', icon: 'box', type: 'sub', active: false, children: [
			// 		{
			// 			title: 'Physical', type: 'sub', children: [
			// 				{ path: '/products/physical/category', title: 'Category', type: 'link' },
			// 				{ path: '/products/physical/sub-category', title: 'Sub Category', type: 'link' },
			// 				{ path: '/products/physical/product-list', title: 'Product List', type: 'link' },
			// 				{ path: '/products/physical/product-detail', title: 'Product Detail', type: 'link' },
			// 				{ path: '/products/physical/add-product', title: 'Add Product', type: 'link' },
			// 			]
			// 		},
			// 		{
			// 			title: 'digital', type: 'sub', children: [
			// 				{ path: '/products/digital/digital-category', title: 'Category', type: 'link' },
			// 				{ path: '/products/digital/digital-sub-category', title: 'Sub Category', type: 'link' },
			// 				{ path: '/products/digital/digital-product-list', title: 'Product List', type: 'link' },
			// 				{ path: '/products/digital/digital-add-product', title: 'Add Product', type: 'link' },
			// 			]
			// 		},
			// 	]
			// },
			// {
			// 	title: 'Sales', icon: 'dollar-sign', type: 'sub', active: false, children: [
			// 		{ path: '/sales/orders', title: 'Orders', type: 'link' },
			// 		{ path: '/sales/transactions', title: 'Transactions', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Coupons', icon: 'tag', type: 'sub', active: false, children: [
			// 		{ path: '/coupons/list-coupons', title: 'List Coupons', type: 'link' },
			// 		{ path: '/coupons/create-coupons', title: 'Create Coupons', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Pages', icon: 'clipboard', type: 'sub', active: false, children: [
			// 		{ path: '/pages/list-page', title: 'List Page', type: 'link' },
			// 		{ path: '/pages/create-page', title: 'Create Page', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Media', path: '/media', icon: 'camera', type: 'link', active: false
			// },
			// {
			// 	title: 'Menus', icon: 'align-left', type: 'sub', active: false, children: [
			// 		{ path: '/menus/list-menu', title: 'Menu Lists', type: 'link' },
			// 		{ path: '/menus/create-menu', title: 'Create Menu', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Users', icon: 'user-plus', type: 'sub', active: false, children: [
			// 		{ path: '/users/list-user', title: 'User List', type: 'link' },
			// 		{ path: '/users/create-user', title: 'Create User', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Vendors', icon: 'users', type: 'sub', active: false, children: [
			// 		{ path: '/vendors/list-vendors', title: 'Vendor List', type: 'link' },
			// 		{ path: '/vendors/create-vendors', title: 'Create Vendor', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Localization', icon: 'chrome', type: 'sub', children: [
			// 		{ path: '/localization/translations', title: 'Translations', type: 'link' },
			// 		{ path: '/localization/currency-rates', title: 'Currency Rates', type: 'link' },
			// 		{ path: '/localization/taxes', title: 'Taxes', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Reports', path: '/reports', icon: 'bar-chart', type: 'link', active: false
			// },
			// {
			// 	title: 'Settings', icon: 'settings', type: 'sub', children: [
			// 		{ path: '/settings/profile', title: 'Profile', type: 'link' },
			// 	]
			// },
			// {
			// 	title: 'Invoice', path: '/invoice', icon: 'archive', type: 'link', active: false
			// },
			// {
			// 	title: 'Login',path: '/auth/login', icon: 'log-in', type: 'link', active: false
			// }
	]
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);


}
function replaceAll(str, from, to){
    var n = '';
    if (str) {
	    for(let g of str){
	      if (g === from) {
	        g = to;  
	      }
	      n += g;
	    }
    }
    return n;
}