import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';

import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AuthService } from 'src/app/shared/service/auth.service';
import { HttpService } from 'src/app/shared/service/http.service';
import { environment } from 'src/environments/environment.prod';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class CreateComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private httpService: HttpService,
  ) {
    this.createRegisterForm();
  }

  @ViewChild('tabSet', {static: false}) tabSet;
  public registerRoomForm: FormGroup;

  tmp = {
    error: '',
    loading: false,
    fileError: '',
    progress: 0,
    progressOverAll: 0,
    loadingFile: false,
  };
  files: File[] = [];
  loading = false;
  faculty: any[] = [];

  uploadFileUrl = [];


  ngOnInit() {
    this.tmp.progressOverAll = 0;
    environment.fakultet.map(f => {this.faculty.push({name: f}); });

  }
  shareCheckedList(event){
    this.registerRoomForm.patchValue({faculty: event.join(', ')});
  }
  async register() {
    // console.log(this.registerRoomForm.value);
    this.httpService.addRoomDormitory(this.registerRoomForm.value)
      .subscribe((res: any) => {
          if (res.status === 200){
            alert('Xona muvaffaqiyatli qo‘shildi!');
          } else {
            alert('Nimadur hato ketti yana bir bor urinib ko‘ring! error: ');
          }
      }, error => {
        alert('Nimadur hato ketti yana bir bor urinib ko‘ring! error: ');
      });
    // this.loading = false;
    // this.tmp.loading = false;
    // this.registerRoomForm.reset();

  }

  createRegisterForm() {
    this.registerRoomForm = this.fb.group({
      building_number: ['', Validators.required],
      floor_number: ['', Validators.required],
      floor_gender: ['erkak', Validators.required],
      room_number: ['', Validators.required],
      num_of_beds: ['', Validators.required],
      faculty: [''],
    });

  }
  onSelectFile(event) {
    this.tmp.fileError = '';
    if (event.rejectedFiles[0]) {
      if (event.rejectedFiles[0].reason === 'type') {
        this.tmp.fileError = 'Fayl turlarini yuklash mumkin: jpeg, png, jpg, doc, pdf';
        return;
      }
      if (event.rejectedFiles[0].reason === 'size') {
        this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
        return;
      }
    }
    this.files.push(...event.addedFiles);
  }
  onRemoveFile(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
  uploadFiles(nth){
    if (!this.files.length){this.register(); return; }
    this.tmp.loadingFile = true;
    const formData = new FormData();
    formData.append('avatar', this.files[nth]);
    this.httpService.uploadFile(formData)
      .subscribe((event) => {
        switch (event.type) {
          case HttpEventType.Sent:
            // console.log('Request has been made!');
            break;
          case HttpEventType.ResponseHeader:
            // console.log('Response header has been received!');
            break;
          case HttpEventType.UploadProgress:
            const eventTotal = event.total ? event.total : 0;
            this.tmp.progress = (Math.round(event.loaded / eventTotal * 100)) / this.files.length;
            // console.log(`Uploaded! ${this.tmp.progress}%`);
            break;
          case HttpEventType.Response:
            this.uploadFileUrl.push(event.body.url);
            if ((nth + 1) === this.files.length){this.register(); }
            else{this.uploadFiles( nth + 1 ); }
            this.tmp.progressOverAll += (this.tmp.progress);
            this.tmp.progress = 0;
        }
      });
  }
  onSubmit() {

  }
}
