import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { Ng2SmartTableModule, LocalDataSource } from 'ng2-smart-table';
import { SkeletonComponent } from './skeleton.component';
import { DatePipe } from '@angular/common';
import { ButtonViewComponent } from './button-view/button-view.component';
import { KickOutComponent } from './button-view/kick-out.component';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
import { SimpleModalService } from 'ngx-simple-modal';
import { AlertComponent } from '../../../shared/components/alert/alert/alert.component';
import { PromptComponent } from '../../../shared/components/alert/prompt/prompt.component';
import { ConfirmComponent } from '../../../shared/components/alert/confirm/confirm.component';

interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-list-students',
  templateUrl: './list-students.component.html',
  styleUrls: ['./list-students.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class ListStudentsComponent implements OnInit {

  public announces_list = [];
  @Input() inputRoomRowData;
  @Output() closeAllStudents = new EventEmitter();

  dataSource: LocalDataSource = new LocalDataSource();
  loading = true;
  pager = 10;
  extendLiving = false;
  inputExtendLivingRowData;
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private simpleModalService: SimpleModalService
  ) {
    this.loading = true;
    this.getStudents();

  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    edit: {
      confirmDelete: true,
    },
    pager: {
      perPage: this.pager
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'left'
    },
    columns: {
      accommodate: {
        title: 'Yotoqxonada turishni davom ettirish',
        type: 'custom',
        renderComponent: ButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowData => {
            this.inputExtendLivingRowData = rowData
            this.extendLiving = true;
          });
        }
      },
      kickOut: {
        title: 'Yotoqxonada chiqarib yuborish',
        type: 'custom',
        renderComponent: KickOutComponent,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowData => {
            this.kickOut(rowData);
          });
        }
      },
      full_name: {
        title: 'Ismi',
        editable: false
      },
      building_number: {
        title: 'Bino raqami',
      },
      floor_number: {
        title: 'Nechinchi qavat',
      },
      room_number: {
        title: 'Xona raqami',
      },
      faculty: {
        title: 'Fakulteti',
      },
      course: {
        title: 'Bosqich',
      },
      group: {
        title: 'Guruh',
      },
      phone: {
        title: 'Telefon'
      },
      last_payment: {
        title: 'Ohirgi to‘langan'
      },
      debtor: {
        title: 'Qarzdorlik',
        // type: 'html'
      }
      // files_new: {
      //   title: 'Fayllar',
      //   type: 'html'
      // },
      // inf_about_father: {
      //   title: 'Otasi haqida ma\'lumot'
      // },
      // inf_about_mother: {
      //   title: 'Onasi haqida ma\'lumot'
      // },
      // mfy_chairman: {
      //   title: 'MFY raisining I.F.Sh. va telefoni'
      // },
      // mfy_chairman_supervisor: {
      //   title: 'MFY noziriningI.F.Sh. va telefoni'
      // },
      // disadvantages: {
      //   title: 'Kamchiliklari'
      // }
    },
  };
  ngOnInit(){

  }
  kickOut(rowData){
    const data = {
      room_id: rowData.dormitory_data[rowData.dormitory_data.length - 1].id,
      accommodating_student_data: {
        first_name: rowData.first_name,
    //     last_name: this.inputStudentRowData.last_name,
    //     patronymic: this.inputStudentRowData.patronymic,
        passport_series: rowData.passport_series
      },
      tutor_uid: rowData.uid,
      accommodating_student_id: rowData.time,
    //   accommodating_in_dormitory: this.inputRoomRowData
    };
    this.simpleModalService.addModal(ConfirmComponent, {
      title: 'Harakatni tasdiqlang',
      message: 'Talabani yotoqxona chiqarmoqchimisiz!'
    }).subscribe(message => {
      if (message){
        this.httpService.kickOutFromDormitory(data)
          .subscribe((res: any) => {
            console.log(res)
            if (res.status === 200){
              var index;
              this.dataSource['data'].map((r: any, idx: number) =>  { if (r.time === rowData.time){index = idx; } });
              this.dataSource['data'].splice(index, 1);
              this.dataSource.refresh();
              alert('Talaba muvaffaqiyatli yotoqxonadan chiqarildi!');
            }else{
              alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
            }
          }, error => {
            console.log(error)
            // alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
          });
      }
    })

  }
  closeExtendLiving(event){
    this.extendLiving = false;
    if (event.status === 'success'){
      this.dataSource['data'].map(res => {
        if (res.uid === event.data.tutor_uid){
          const finish_date = event.data.new_period_data.finish_date;
          res.finish_date = finish_date.day + '.' + finish_date.month + '.' + finish_date.year;
        }
      });
      this.dataSource.refresh();
    }
    // this.accommodateStudent = false;
  }
  getStudents(){
    this.httpService.getAllAccommodatedStudents()
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        const currentTimestamp = new Date(result.time.year, result.time.month, result.time.day).getTime();
        this.genTable(result.students, currentTimestamp);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
    });
  }

  // genSkeleton(){
  //       for (let i = 0; i < this.pager; i++) {this.announces_list.push({title: '', context: '', files: ''}); }
  //       this.dataSource.load(this.announces_list);
  //       this.loading = false;
  //       this.getStudents(1);
  // }
  genTable(students, currentTimestamp){
          students.forEach((res, idx) => {
            if (res.dormitory_status){
              // let a;
              res.not_changed = res;
              res.full_name = res.first_name + ' ' + res.last_name + ' ' + res.patronymic;
              res.building_number = res.dormitory_data[res.dormitory_data.length - 1].building_number;
              res.floor_number = res.dormitory_data[res.dormitory_data.length - 1].floor_number;
              res.room_number = res.dormitory_data[res.dormitory_data.length - 1].room_number;
              res.date_of_birth_new = res.date_of_birth.day + '.' + res.date_of_birth.month + '.' + res.date_of_birth.year;
              res.permanent_address = res.permanent_address_region + ', ' + res.permanent_address_district + ', ' + res.permanent_address_street;
              const ddll = res.dormitory_data.length - 1;
              const ddlpl = res.dormitory_data[ddll].period.length - 1;
              const start_date = res.dormitory_data[ddll].period[ddlpl].start_date;
              const finish_date = res.dormitory_data[ddll].period[ddlpl].finish_date;
              res.last_payment = start_date.day + '.' + start_date.month + '.' + start_date.year + ' dan ' + finish_date.day + '.' + finish_date.month + '.' + finish_date.year + ' gacha';
              const finishTimestamp = new Date(finish_date.year, finish_date.month, finish_date.day).getTime();
              if (finishTimestamp < currentTimestamp){ res.debtor = 'Qarz'; }
            }
          this.dataSource['data'].push(res);
        });
          this.dataSource.refresh();
          this.loading = false;
  }
  onEditConfirm(event){
  }
  onDeleteConfirm(event){
    this.httpService.removeMyStudent(this.authService.user_data.id, event.data.time)
    .subscribe((result: any) => {
      if (result.status === 200) {
        alert('Tadbir muvaffaqiyatli ochirildi!');
        event.confirm.resolve();
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
}
