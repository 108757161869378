import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  getDoc(content){
    return this.http.post(environment.http.get.path,
      {
        http_auth: environment.http_auth,
        file_path: environment.http.get.file,
        content
      }
    );
  }
  getFieldsInform(){
    return this.http.post(environment.http.getFieldsInform.path,
      {
        http_auth: environment.http_auth,
        data: 'data'
      }
    );
  }
  acceptResponse(data){
    return this.http.post(environment.http.acceptResponse.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }
  rejectResponse(data){
    return this.http.post(environment.http.rejectResponse.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }
  addRoomDormitory(data){
    return this.http.post(environment.http.addRoomDormitory.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }
  accommodateStudent(data){
    return this.http.post(environment.http.accommodateStudent.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }
  kickOutFromDormitory(data){
    return this.http.post(environment.http.kickOutFromDormitory.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }

  extendLivingDormitory(data){
    return this.http.post(environment.http.extendLivingDormitory.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }
  getDormitoryRooms(){
    return this.http.post(environment.http.getDormitoryRooms.path,
      {
        http_auth: environment.http_auth,
      }
    );
  }
  removeDormitoryRoom(data){
    return this.http.post(environment.http.removeDormitoryRoom.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }

  setDocInc(inc_path, obj_path, obj){
    return this.http.post(environment.http.setInc.path,
      {
        http_auth: environment.http_auth,
        file_path: environment.http.setInc.file,
        inc_path,
        obj_path,
        obj
      }
    );
  }
  delDoc(obj_path){
    return this.http.post(environment.http.del.path,
      {
        http_auth: environment.http_auth,
        file_path: environment.http.del.file,
        obj_path
      }
    );
  }
  setIncDelUpd(inc_path, obj_path, obj, del_path, inc_pvogrd_path, inc_pvogrd_obj){
    return this.http.post(environment.http.setIncDelUpd.path,
      {
        http_auth: environment.http_auth,
        file_path: environment.http.setIncDelUpd.file,
        inc_path,
        obj_path,
        obj,
        del_path,
        inc_pvogrd_path,
        inc_pvogrd_obj
      }
    );
  }
  remDocDownGrade(pvo_added_id, indexId, data_added_id, grade){
    return this.http.post(environment.http.remDocDownGrade.path,
      {
        http_auth: environment.http_auth,
        pvo_added_id,
        index_id: indexId,
        data_added_id,
        grade
      }
    );
  }
  setNewPvo(new_pvo){
    return this.http.post(environment.http.setNewPvo.path,
      {
        http_auth: environment.http_auth,
        new_pvo
      }
    );
  }
  removePvo(pvo_added_id){
    return this.http.post(environment.http.removePvo.path,
      {
        http_auth: environment.http_auth,
        pvo_added_id
      }
    );
  }
  createAdminRequest(data){
    return this.http.post(environment.http.createAdminRequest.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }
  getAdminRequests(){
    return this.http.post(environment.http.getAdminRequests.path,
      {
        http_auth: environment.http_auth,
        data: 'data'
      }
    );
  }
  getCompletedRequests(){
    return this.http.post(environment.http.getCompletedRequests.path,
      {
        http_auth: environment.http_auth,
        data: 'data'
      }
    );
  }
  createAnnounce(announce){
    return this.http.post(environment.http.createAnnounce.path,
      {
        http_auth: environment.http_auth,
        announce
      }
    );
  }
  createEvents(data){
    return this.http.post(environment.http.createEvents.path,
      {
        http_auth: environment.http_auth,
        announce: data
      }
    );
  }
  getRequestResponses(responses){
    return this.http.post(environment.http.getRequestResponses.path,
      {
        http_auth: environment.http_auth,
        responses
      }
    );
  }
  getAcceptedResponses(responses){
    return this.http.post(environment.http.getAcceptedResponses.path,
      {
        http_auth: environment.http_auth,
        responses
      }
    );
  }
  tutorResponse(data){
    return this.http.post(environment.http.tutorResponse.path,
      {
        http_auth: environment.http_auth,
        response: data
      }
    );
  }
  addStudent(data){
    return this.http.post(environment.http.addStudent.path,
      {
        http_auth: environment.http_auth,
        data
      }
    );
  }
  getAnnounces(){
    return this.http.post(environment.http.getAnnounces.path,
      {
        http_auth: environment.http_auth,
        data: 'data'
      }
    );
  }
  getMyEvents(uid, page, pager){
    return this.http.post(environment.http.getMyEvents.path,
      {
        http_auth: environment.http_auth,
        uid,
        page,
        pager
      }
    );
  }
  getStudents(uid){
    return this.http.post(environment.http.getStudents.path,
      {
        http_auth: environment.http_auth,
        uid,
      }
    );
  }
  getAllStudents(){
    return this.http.post(environment.http.getAllStudents.path,
      {
        http_auth: environment.http_auth,
      }
    );
  }
  getAllAccommodatedStudents(){
    return this.http.post(environment.http.getAllAccommodatedStudents.path,
      {
        http_auth: environment.http_auth,
      }
    );
  }
  removeMyEvents(uid, eventId){
    return this.http.post(environment.http.removeMyEvents.path,
      {
        http_auth: environment.http_auth,
        uid,
        eventId
      }
    );
  }
  removeMyStudent(uid, studentId){
    return this.http.post(environment.http.removeMyStudent.path,
      {
        http_auth: environment.http_auth,
        uid,
        studentId
      }
    );
  }
  controlIndexStatus(index_data){
    return this.http.post(environment.http.controlIndexStatus.path,
      {
        http_auth: environment.http_auth,
        index_data
      }
    );
  }
  removeAnnounce(announce_id){
    return this.http.post(environment.http.removeAnnounce.path,
      {
        http_auth: environment.http_auth,
        announce_id
      }
    );
  }
  removeRequest(request_file_name){
    return this.http.post(environment.http.removeRequest.path,
      {
        http_auth: environment.http_auth,
        request_file_name
      }
    );
  }
  editUserAccaunt(pvo_added_id, new_data){
    return this.http.post(environment.http.editUserAccaunt.path,
      {
        http_auth: environment.http_auth,
        pvo_added_id,
        new_data,
      }
    );
  }
  createIndex(id, new_index){
    return this.http.post(environment.http.createIndex.path,
      {
        http_auth: environment.http_auth,
        id,
        new_index,
      }
    );
  }
  signUp(user_data){
    return this.http.post(environment.http.signUp.path,
      {
        http_auth: environment.http_auth,
        user_data
      }
    );
  }
  signIn(user_data){
    return this.http.post(environment.http.signIn.path,
      {
        http_auth: environment.http_auth,
        user_data
      }
    );
  }
  getUsers(){
    return this.http.post(environment.http.getUsers.path,
      {
        http_auth: environment.http_auth,
      }
    );
  }
  updateUserGroups(user_data){
    return this.http.post(environment.http.updateUserGroups.path,
      {
        http_auth: environment.http_auth,
        user_data
      }
    );
  }
  testtest(path){
    return this.http.post(path, []);
  }
  googscho_upd_cit(url, old_citation, pvo_added_id, field_added_id){
  // googscho_upd_cit(url){
    return this.http.post(environment.http.googleScholarCited.path,
      {
        http_auth: environment.http_auth,
        url,
        old_citation,
        pvo_added_id,
        field_added_id
      }
    );
  }
  // uploadFile(files){
  //   return this.http.post(environment.http.uploadFile.path,
  //      {
  //        files: files
  //      }
  //    )
  // }
  // SERVER_URL: string = "http://127.0.0.1:8000";

  public uploadFile(data) {
    // let uploadURL = ;
    // console.log('url', )
    return this.http.post<any>(environment.http.uploadFile.path, data, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
