import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StatisticsComponent } from './statistics.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: StatisticsComponent,
        data: {
          title: "Statistics",
          breadcrumb: "Statistics"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatisticsRoutingModule { }