import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { DormitoryAccommodationRoutingModule } from './dormitory_accommodation-routing.module';
import { ListStudentsComponent } from './list-students/list-students.component';
import { AccommodateStudentComponent } from './accommodation/accommodate-student.component';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonComponent } from './list-students/skeleton.component';
import { DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ButtonViewComponent } from './list-students/button-view/button-view.component';

@NgModule({
  declarations: [ListStudentsComponent, AccommodateStudentComponent, SkeletonComponent, ButtonViewComponent],
  imports: [
    CommonModule,
    DormitoryAccommodationRoutingModule,
    NgxDatatableModule,
    FormsModule,
	  Ng2SmartTableModule,
    NgbModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot({}),
    ReactiveFormsModule,
    IonicModule.forRoot()
  ],
  providers: [NgbActiveModal,DatePipe],
  exports: [ListStudentsComponent]
})
export class DormitoryAccommodationModule { }
