<script src="../../../../../../../marketing full/platform/src/environments/environment.prod.ts"></script>
<div class="container-fluid" [@fadeInAndOut]>
  <div class="row">
    <div class="col-sm-12">
      <div class="card tab2-card">
        <div class="card-header">
          <h5>Yangi xona qo‘shish</h5>
        </div>
        <div class="card-body tab2-card">
          <form [formGroup]="registerRoomForm" class="needs-validation user-add" novalida>
            <div class="form-group row">
              <label for="building_number" class="col-xl-3 col-md-4">
                <span>*</span>
                Bino raqami
              </label>
              <div class="col-md-8">
                <select class="form-control digits" formControlName="building_number" id="building_number">
                  <option value=""  value> -- tanlang -- </option>
                  <option value="1" >1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="floor_number" class="col-xl-3 col-md-4">
                <span>*</span>
                Nechinchi qavat
              </label>
              <div class="col-md-8">
                <select class="form-control digits" formControlName="floor_number" id="floor_number">
                  <option value=""  value> -- tanlang -- </option>
                  <option value="1" >1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="faculty" class="col-xl-3 col-md-4">
                <span>*</span>
                Fakultet
              </label>
              <div class="col-md-8">
                <app-multi-select-dropdown [list]="faculty" (shareCheckedList)="shareCheckedList($event)"></app-multi-select-dropdown>

                <!--                <select class="form-control digits" formControlName="faculty" id="faculty">-->
<!--                  <option value="" selected value> &#45;&#45; tanlang &#45;&#45; </option>-->
<!--                  <option *ngFor="let k of faculty;" [value]="k">{{k}}</option>-->
<!--                </select>-->
              </div>
            </div>
            <div class="form-group row">
              <label class="col-xl-3 col-md-4">
                <span>*</span>
                Qavatda kimlar yashaydi
              </label>
              <div class="col-md-8">
                <div class="m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                  <label class="d-block" for="edo-ani0">
                    <input class="radio_animated" formControlName="floor_gender" id="edo-ani0" type="radio" value="erkak">
                    <label for="edo-ani0">Erkak</label>
                  </label>
                  <label class="d-block" for="edo-ani1">
                    <input class="radio_animated" formControlName="floor_gender" id="edo-ani1" type="radio" value="ayol">
                    <label for="edo-ani1">Ayol</label>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="room_number" class="col-xl-3 col-md-4">
                <span>*</span>
                Xona raqami
              </label>
              <div class="col-md-8">
                <input
                  class="form-control"
                  formControlName="room_number"
                  placeholder="Xona raqami"
                  type="text"
                  id="room_number"
                >
              </div>
            </div>
            <div class="form-group row">
              <label for="num_of_beds" class="col-xl-3 col-md-4">
                <span>*</span>
                Xonada necha kishi yashaydi
              </label>
              <div class="col-md-8">
                <select class="form-control digits" formControlName="num_of_beds" id="num_of_beds">
                  <option value=""  value> -- tanlang -- </option>
                  <option value="1" >1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
            </div>
            <div class="pull-left">
              <button type="submit"  (click)="uploadFiles(0)"  class="btn btn-primary" [disabled]="!registerRoomForm.valid || loading">
                Qo'shish<span *ngIf="loading">...</span>
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>
<div class="lds-wrap" *ngIf="tmp.loadingFile">
  <circle-progress
    [percent]="tmp.progressOverAll + tmp.progress"
    [radius]="1.2"
    [outerStrokeWidth]="0.35"
    [innerStrokeWidth]="0.35"
    [space] = "-0.35"
    [outerStrokeColor]="'#007bff'"
    [innerStrokeColor]="'#e7e8ea'"
    [titleFontSize]= "0.5"
    [unitsFontSize]= "0.5"
    [showSubtitle] = "false"
    [animation]="true"
    [animationDuration]="300"
    [startFromZero]="false"
    [responsive]="true"
  ></circle-progress>
</div>
<div class="lds-wrap" *ngIf="tmp.loading">
  <div class="lds-dual-ring"></div>
</div>
