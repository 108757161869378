import { Component, OnInit } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { DatePipe } from '@angular/common';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-list-announce',
  templateUrl: './list-announce.component.html',
  styleUrls: ['./list-announce.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class ListAnnounceComponent implements OnInit {

  public announces_list = []
  loading = true;
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private datePipe: DatePipe
  ) {
    this.loading = true;
    this.getAnnounces();
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    columns: {
      // avatar: {
      //   title: 'Avatar',
      //   type: 'html'
      // },
      title: {
        title: 'Sarlavha',
      },
      context: {
        title: 'Mazmuni'
      },
      createdAt: {
        title: 'Sana',
      },
      files: {
        title: 'Fayllar',
        type: 'html'
      }
    },
  };
  ngOnInit(){

  }
  getAnnounces(){
    this.httpService.getAnnounces()
    .subscribe(async(result:any)=>{
      if (result.status === 200) {
        this.genTable(result.announces);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error =>{
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
  genTable(announces){
        announces.sort((a:any, b:any) =>{
          return (b.time - a.time)?(b.time - a.time):(a.time - b.time);
        })
        announces.forEach(res=>{
          var a;
          if(res.files.length){
             // res.files = 'fayllar';
              a = '<div class="file_download">';
              res.files.forEach((fet, indexFile)=>{
                a += `<a href="${fet}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`
              })
              a += '</div>'
              res.files = a;
          }
          res['createdAt'] = this.datePipe.transform( new Date(res.time * 1000) , 'dd/MM/yyyy hh:mm');
          this.announces_list.push(res);
        })
        this.loading = false;
  }
  onDeleteConfirm(event){
    this.httpService.removeAnnounce(event.data.time)
    .subscribe((result:any)=>{
      if (result.status === 200) {
        alert('E\'lon muvaffaqiyatli ochirildi!');
        event.confirm.resolve();
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error =>{
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
}
