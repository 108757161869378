import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { TutorStudentsRoutingModule } from './tutor-students-routing.module';
import { ListStudentsComponent } from './list-students/list-students.component';
import { CreateStudentComponent } from './create-student/create-student.component';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonComponent } from './list-students/skeleton.component';
import { DatePipe } from '@angular/common';
@NgModule({
  declarations: [ListStudentsComponent, CreateStudentComponent, SkeletonComponent],
  imports: [
    CommonModule,
    TutorStudentsRoutingModule,
    NgxDatatableModule,
    FormsModule,
	  Ng2SmartTableModule,
    NgbModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot({}),
    ReactiveFormsModule
  ],
  providers: [NgbActiveModal,DatePipe],
})
export class TutorStudentsModule { }
