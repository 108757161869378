import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { DormitoryRoomRoutingModule } from './dormitory-room-routing.module';
import { ListComponent } from './list/list.component';
import { CreateComponent } from './create/create.component';
import { FormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { SkeletonComponent } from './list/skeleton.component';
import { DormitoryAccommodationModule } from '../dormitory_accommodation/dormitory_accommodation.module';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../../shared/shared.module';
import { ButtonViewComponent } from './list/button-view/button-view.component';

@NgModule({
  declarations: [ListComponent, CreateComponent, SkeletonComponent, ButtonViewComponent],
  imports: [
    CommonModule,
    SharedModule,
    DormitoryAccommodationModule,
    DormitoryRoomRoutingModule,
    NgxDatatableModule,
    FormsModule,
	  Ng2SmartTableModule,
    NgbModule,
    NgxDropzoneModule,
    NgCircleProgressModule.forRoot({}),
    ReactiveFormsModule,
    IonicModule.forRoot()
  ],
  providers: [NgbActiveModal],
})
export class DormitoryRoomModule { }
