// ferpi///////////
export const environment_ferpi = {
  otm: 'ferpi',
  firebase: {
    email: 'tutor0ferpi',
    pa: '8u2KXv6O4G%3',
    ad: '1pDGPx3U9R!g',
  },
  db: {
    data: '192.168.10.220 admin_tutor Qw...5',
  },
  production: true,
  http_auth: '5KWBffF64Q7v6J9fLA4K',
  otm_name: 'Tyutorlar Elektron Platformasi',
  firebaseConfig: {
    // pvorey
    // apiKey: 'AIzaSyCtFGfn_M96D3hzQlxxUmMGTKHcNsp6oR4',
    // authDomain: 'fdu1-138e9.firebaseapp.com',
    // projectId: 'fdu1-138e9',
    // storageBucket: 'fdu1-138e9.appspot.com',
    // messagingSenderId: '86663595785',
    // appId: '1:86663595785:web:fd512e84a8ce8cf86375d8',
    // measurementId: 'G-B6GKYS7M9J'
    apiKey: 'AIzaSyBUK8EhYydnkGbHcACY1m9WDKcAehrP-VY',
    authDomain: 'tutor-e7832.firebaseapp.com',
    projectId: 'tutor-e7832',
    storageBucket: 'tutor-e7832.appspot.com',
    messagingSenderId: '562380728251',
    appId: '1:562380728251:web:82cae687b57b09981ac69e',
    measurementId: 'G-M4SC0KWJ5Z',
  },
  // https://tutor.ferpi.uz/db/
  http: {
    get: {
      path: 'https://tutor.ferpi.uz/db/get_data.php',
      file: 'pvorey.txt',
    },
    getFieldsInform: {
      path: 'https://tutor.ferpi.uz/db/get_fields_inform.php',
    },
    setInc: {
      path: 'https://tutor.ferpi.uz/db/set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: 'https://tutor.ferpi.uz/db/del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: 'https://tutor.ferpi.uz/db/set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: 'https://tutor.ferpi.uz/db/rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: 'https://tutor.ferpi.uz/db/google_ scholar.php',
    },
    setNewPvo: {
      path: 'https://tutor.ferpi.uz/db/set_new_pvo.php',
    },
    removePvo: {
      path: 'https://tutor.ferpi.uz/db/remove_pvo.php',
    },
    createAdminRequest: {
      path: 'https://tutor.ferpi.uz/db/create_admin_request.php',
    },
    tutorResponse: {
      path: 'https://tutor.ferpi.uz/db/tutor_response.php',
    },
    getRequestResponses: {
      path: 'https://tutor.ferpi.uz/db/get_request_responses.php',
    },
    getAcceptedResponses: {
      path: 'https://tutor.ferpi.uz/db/get_accepted_responses.php',
    },
    getAdminRequests: {
      path: 'https://tutor.ferpi.uz/db/get_admin_requests.php',
    },
    getCompletedRequests: {
      path: 'https://tutor.ferpi.uz/db/get_completed_requests.php',
    },
    acceptResponse: {
      path: 'https://tutor.ferpi.uz/db/accept_response.php',
    },
    rejectResponse: {
      path: 'https://tutor.ferpi.uz/db/reject_response.php',
    },
    controlIndexStatus: {
      path: 'https://tutor.ferpi.uz/db/control_index_status.php',
    },
    createAnnounce: {
      path: 'https://tutor.ferpi.uz/db/create_announce.php',
    },
    createEvents: {
      path: 'https://tutor.ferpi.uz/db/create_event.php',
    },
    addStudent: {
      path: 'https://tutor.ferpi.uz/db/add_student.php',
    },
    signUp: {
      path: 'https://tutor.ferpi.uz/db/sign_up.php',
    },
    signIn: {
      path: 'https://tutor.ferpi.uz/db/sign_in.php',
    },
    getUsers: {
      path: 'https://tutor.ferpi.uz/db/get_users.php',
    },
    updateUserGroups: {
      path: 'https://tutor.ferpi.uz/db/update_user_groups.php',
    },
    getAnnounces: {
      path: 'https://tutor.ferpi.uz/db/get_announces.php',
    },
    getMyEvents: {
      path: 'https://tutor.ferpi.uz/db/get_my_events.php',
    },
    getStudents: {
      path: 'https://tutor.ferpi.uz/db/get_students.php',
    },
    getAllStudents: {
      path: 'https://tutor.ferpi.uz/db/get_all_students.php',
    },
    getAllAccommodatedStudents: {
      path: 'https://tutor.ferpi.uz/db/get_all_accommodated_students.php',
    },
    extendLivingDormitory: {
      path: 'https://tutor.ferpi.uz/db/extend_living_dormitory.php',
    },
    kickOutFromDormitory: {
      path: 'https://tutor.ferpi.uz/db/kick-out-from-dormitory.php',
    },
    removeMyEvents: {
      path: 'https://tutor.ferpi.uz/db/remove_my_events.php',
    },
    removeMyStudent: {
      path: 'https://tutor.ferpi.uz/db/remove_my_student.php',
    },
    removeDormitoryRoom: {
      path: 'https://tutor.ferpi.uz/db/remove_dormitory_room.php',
    },

    getDormitoryRooms: {
      path: 'https://tutor.ferpi.uz/db/get_dormitory_rooms.php',
    },
    removeAnnounce: {
      path: 'https://tutor.ferpi.uz/db/remove_announce.php',
    },
    removeRequest: {
      path: 'https://tutor.ferpi.uz/db/remove_request.php',
    },
    editUserAccaunt: {
      path: 'https://tutor.ferpi.uz/db/edit_user_account.php',
    },
    addRoomDormitory: {
      path: 'https://tutor.ferpi.uz/db/add_room_dormitory.php',
    },
    accommodateStudent: {
      path: 'https://tutor.ferpi.uz/db/accommodate_student.php',
    },
    createIndex: {
      path: 'https://tutor.ferpi.uz/db/create_index.php',
    },
    uploadFile: {
      path: 'https://tutor.ferpi.uz/db/file_upload/upload.php',
      // 'path': 'http://hujjat.ferpi.uz/file_upload/upload.php'
    },
  },
  tizim: [
    {
      fakultet: 'Mexanika - mashinasozlik',
      kafedralar: [
        'Mashinasozlik texnologiyasi va avtomatlashtirish',
        'Oliy matematika',
        'Tadbiqiy mexanika',
        'Texnologik mashinalar va jihozlar',
        'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
      ],
    },
    {
      fakultet: 'Energetika',
      kafedralar: [
        'Elektr energetika',
        'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
        'Fizika',
      ],
    },
    {
      fakultet: 'Kimyo texnologiya',
      kafedralar: [
        'Hayot faoliyati xavfsizligi',
        'Jismoniy tarbiya',
        'Kimyoviy texnologiya',
        'Oziq-ovqatlar texnologiyasi',
        "Qishloq xo'jalik mahsulotlarini saqlash va ularni qayta ishlash texnologiya",
        'Umumiy kimyo',
      ],
    },
    {
      fakultet: 'Qurilish',
      kafedralar: [
        'Bino va inshootlar qurilishi',
        'Muhandislik kommunikatsiyalari qurilishi va montaji',
        "O'zbekiston tarixi va Ijtimoiy fanlar",
        'Geodeziya, kartografiya va kadastr',
      ],
    },
    {
      fakultet: 'Ishlab chiqarishda boshqaruv',
      kafedralar: [
        'Iqtisodiyot',
        'Menejment',
        "Tillarni o'rgatish",
        'Buxgalteriya xisobi va audit',
      ],
    },
    {
      fakultet: "Yengil sanoat va to'qimachilik",
      kafedralar: [
        'Yengil sanoat texnologiyalari va jixozlari',
        "O'zbek tili va adabiyoti",
        'Tabiiy tolalar',
      ],
    },
    {
      fakultet: 'Arxitektura va qurilish materiallari',
      kafedralar: [
        'Arxitektura',
        'Qurilish materiallari buyumlari va konstruksiyalari ishlab chiqish',
        'Chizma geometriya va muhandislik grafikasi',
      ],
    },
    {
      fakultet: 'Kompyuterlashgan loyihalash tizimlari',
      kafedralar: [
        'Elektronika va asbobsozlik',
        'Intellektual muhandislik tizimlari',
        'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
      ],
    },
  ],
  grastud: 556,
  fakultet: [
    'Mexanika - mashinasozlik',
    'Energetika',
    'Kimyo texnologiya',
    'Qurilish',
    'Ishlab chiqarishda boshqaruv',
    "Yengil sanoat va to'qimachilik",
    'Kompyuterlashgan loyihalash tizimlari',
  ],
  kafedra: [
    'Mashinasozlik texnologiyasi va avtomatlashtirish',
    'Oliy matematika',
    'Tadbiqiy mexanika',
    'Texnologik mashinalar va jihozlar',
    'Yer usti transport tizimlari va ularni ekspluatatsiyasi',
    'Elektr energetika',
    'Elektr texnikasi, elektr mexanikasi va elektr texnologiyalari',
    'Fizika',
    'Hayot faoliyati xavfsizligi',
    'Jismoniy tarbiya',
    'Kimyoviy texnologiya',
    'Oziq-ovqatlar texnologiyasi',
    "Qishloq xo'jalik mahsulotlarini saqlash va ularni qayta ishlash texnologiya",
    'Umumiy kimyo',
    'Bino va inshootlar qurilishi',
    'Muhandislik kommunikatsiyalari qurilishi va montaji',
    "O'zbekiston tarixi va Ijtimoiy fanlar",
    'Geodeziya, kartografiya va kadastr',
    'Iqtisodiyot',
    'Menejment',
    "Tillarni o'rgatish",
    'Buxgalteriya xisobi va audit',
    'Yengil sanoat texnologiyalari va jixozlari',
    "O'zbek tili va adabiyoti",
    'Tabiiy tolalar',
    'Arxitektura',
    'Qurilish materiallari buyumlari va konstruksiyalari ishlab chiqish',
    'Chizma geometriya va muhandislik grafikasi',
    'Elektronika va asbobsozlik',
    'Intellektual muhandislik tizimlari',
    'Metrologiya standartlashtirish va maxsulot sifati menejmenti',
  ],
};
//////////////////
// fdu///////////
const url_fdu = 'https://tutor.ferpi.uz/db/';
export const environment = {
  otm: 'fdu',
  firebase: {
    email: 'tutor0fdu1',
    pa: 'snST!edx039K',
    ad: 'Y97Y632p1&aT',
  },
  db: {
    data: '192.168.10.220 admin_tutor Qw...5',
  },
  production: true,
  http_auth: '5KWBffF64Q7v6J9fLA4K',
  otm_name: 'Tyutorlar Elektron Platformasi',
  firebaseConfig: {
    // pvorey
    apiKey: 'AIzaSyClLbb-JBABFsl2Q8rOVF-skNljOq7KmsY',
    authDomain: 'tyutor-2f2c3.firebaseapp.com',
    projectId: 'tyutor-2f2c3',
    storageBucket: 'tyutor-2f2c3.appspot.com',
    messagingSenderId: '699762781820',
    appId: '1:699762781820:web:4d49d6b8973f59370ca3f9',
    measurementId: 'G-C77WVQ83N8',
  },
  // https://kpi.fdu.uz/tyutor/database
  http: {
    get: {
      path: url_fdu + 'get_data.php',
      file: 'pvorey.txt',
    },
    getFieldsInform: {
      path: url_fdu + 'get_fields_inform.php',
    },
    setInc: {
      path: url_fdu + 'set_data_inc.php',
      file: 'pvorey.txt',
    },
    del: {
      path: url_fdu + 'del_data.php',
      file: 'pvorey.txt',
    },
    setIncDelUpd: {
      path: url_fdu + 'set_inc_del_upd.php',
      file: 'pvorey.txt',
    },
    remDocDownGrade: {
      path: url_fdu + 'rem_doc_with_down_grade.php',
    },
    googleScholarCited: {
      path: url_fdu + 'google_ scholar.php',
    },
    setNewPvo: {
      path: url_fdu + 'set_new_pvo.php',
    },
    removePvo: {
      path: url_fdu + 'remove_pvo.php',
    },
    createAdminRequest: {
      path: url_fdu + 'create_admin_request.php',
    },
    tutorResponse: {
      path: url_fdu + 'tutor_response.php',
    },
    getRequestResponses: {
      path: url_fdu + 'get_request_responses.php',
    },
    getAcceptedResponses: {
      path: url_fdu + 'get_accepted_responses.php',
    },
    getAdminRequests: {
      path: url_fdu + 'get_admin_requests.php',
    },
    getCompletedRequests: {
      path: url_fdu + 'get_completed_requests.php',
    },
    acceptResponse: {
      path: url_fdu + 'accept_response.php',
    },
    rejectResponse: {
      path: url_fdu + 'reject_response.php',
    },
    controlIndexStatus: {
      path: url_fdu + 'control_index_status.php',
    },
    createAnnounce: {
      path: url_fdu + 'create_announce.php',
    },
    createEvents: {
      path: url_fdu + 'create_event.php',
    },
    addStudent: {
      path: url_fdu + 'add_student.php',
    },
    signUp: {
      path: url_fdu + 'sign_up.php',
    },
    signIn: {
      path: url_fdu + 'sign_in.php',
    },
    getUsers: {
      path: url_fdu + 'get_users.php',
    },
    updateUserGroups: {
      path: url_fdu + 'update_user_groups.php',
    },
    getAnnounces: {
      path: url_fdu + 'get_announces.php',
    },
    getMyEvents: {
      path: url_fdu + 'get_my_events.php',
    },
    getStudents: {
      path: url_fdu + 'get_students.php',
    },
    getAllStudents: {
      path: url_fdu + 'get_all_students.php',
    },
    getAllAccommodatedStudents: {
      path: url_fdu + 'get_all_accommodated_students.php',
    },
    extendLivingDormitory: {
      path: url_fdu + 'extend_living_dormitory.php',
    },
    kickOutFromDormitory: {
      path: url_fdu + 'kick-out-from-dormitory.php',
    },
    removeMyEvents: {
      path: url_fdu + 'remove_my_events.php',
    },
    removeMyStudent: {
      path: url_fdu + 'remove_my_student.php',
    },
    removeDormitoryRoom: {
      path: url_fdu + 'remove_dormitory_room.php',
    },

    getDormitoryRooms: {
      path: url_fdu + 'get_dormitory_rooms.php',
    },
    removeAnnounce: {
      path: url_fdu + 'remove_announce.php',
    },
    removeRequest: {
      path: url_fdu + 'remove_request.php',
    },
    editUserAccaunt: {
      path: url_fdu + 'edit_user_account.php',
    },
    addRoomDormitory: {
      path: url_fdu + 'add_room_dormitory.php',
    },
    accommodateStudent: {
      path: url_fdu + 'accommodate_student.php',
    },
    createIndex: {
      path: url_fdu + 'create_index.php',
    },
    uploadFile: {
      path: url_fdu + 'file_upload/upload.php',
      // 'path': 'http://hujjat.ferpi.uz/file_upload/upload.php'
    },
  },
  tizim: [
    {
      fakultet: 'Fizika-texnika',
      kafedralar: ['Fizika', 'Texnologik taʼlim'],
    },
    {
      fakultet: 'Matematika-informatika',
      kafedralar: [
        'Matematika',
        'Matematik analiz va differentsial tenglamalar',
        'Аmaliy matematika va informatika',
        'Аxborot texnologiyalari',
      ],
    },
    {
      fakultet: 'Tabiiy fanlar',
      kafedralar: [
        'Kimyo',
        'Geografiya',
        'Ekologiya',
        'Zoologiya va umumiy biologiya',
        'Botanika va biotexnologiya',
      ],
    },
    {
      fakultet: 'Agrar qo‘shma',
      kafedralar: [
        'Аholi tomorqalaridan samarali foydalanish va dorivor o‘simliklar',
        'Tuproqshunoslik',
        'Mevachilik va sabzavotchilik',
      ],
    },
    {
      fakultet: 'Chet tillari',
      kafedralar: [
        'Ingliz tili',
        'Nemis va frantsuz tillari',
        'Gumanitar yoʼnalishlar boʼyicha chet tillari',
        'Tabiiy yoʼnalishlar boʼyicha chet tillari',
      ],
    },
    {
      fakultet: 'Ingliz tili va adabiyoti',
      kafedralar: ['Аmaliy ingliz tili', 'Ingliz tili oʼqitish metodikasi'],
    },
    {
      fakultet: 'Filologiya',
      kafedralar: [
        'Tilshunoslik',
        'Аdabiyotshunoslik',
        'Oʼzbek tili va adabiyoti',
        'Rus filologiyasi',
        'Rus tili metodikasi',
      ],
    },
    {
      fakultet: 'Tarix',
      kafedralar: [
        'Jahon tarixi',
        'Oʼzbekiston tarixi',
        'Sotsiologiya',
        'Falsafa',
        'Ijtimoiy ish',
        'Huquq ta’limi',
      ],
    },
    {
      fakultet: 'Pedagogika-psixologiya',
      kafedralar: ['Pedagogika', 'Psixologiya'],
    },
    {
      fakultet: 'Sanʼatshunoslik',
      kafedralar: [
        'Tasviriy sanʼat',
        'Musiqiy taʼlim va madaniyat',
        'Vokal va cholgʼu ijrochiligi',
      ],
    },
    {
      fakultet: 'Maktabgacha va boshlangʼich taʼlim',
      kafedralar: ['Boshlangʼich taʼlim uslubiyoti', 'Maktabgacha taʼlim'],
    },
    {
      fakultet: 'Jismoniy madaniyat',
      kafedralar: [
        'Jismoniy madaniyat',
        'Jismoniy madaniyat nazariyasi va uslubiyoti',
        'Sport oʼyinlari',
      ],
    },
    {
      fakultet: 'Iqtisodiyot',
      kafedralar: [
        'Jahon va mintaqa iqtisodiyoti',
        'Iqtisodiyot va servis',
        'Moliya',
        'Buxgalteriya hisobi va iqtisodiy taxlil',
      ],
    },
    {
      fakultet: 'Harbiy taʼlim',
      kafedralar: ['Harbiy taʼlim'],
    },
    {
      fakultet: 'Sirtqi boʼlim',
      kafedralar: ['Аniq va tabiiy fanlar', 'Ijtimoiy gumanitar fanlar'],
    },
  ],
  grastud: 556,
  fakultet: [
    'Fizika-texnika',
    'Matematika-informatika',
    'Tabiiy fanlar',
    'Zootexniya',
    'Uzumchilik, mevachilik va sabzavotchilik',
    'Chet tillari',
    'Ingliz tili va adabiyoti',
    'Filologiya',
    'Tarix',
    'Pedagogika-psixologiya',
    'Sanʼatshunoslik',
    'Maktabgacha va boshlangʼich taʼlim',
    'Jismoniy madaniyat',
    'Iqtisodiyot',
    'Harbiy taʼlim',
    'Sirtqi boʼlim',
  ],
  kafedra: [
    'Fizika',
    'Texnologik taʼlim',
    'Matematika',
    'Matematik analiz va differentsial tenglamalar',
    'Аmaliy matematika va informatika',
    'Аxborot texnologiyalari',
    'Kimyo',
    'Geografiya',
    'Ekologiya',
    'Zoologiya va umumiy biologiya',
    'Botanika va biotexnologiya',
    'Zootexniya va agronomiya',
    'Tuproqshunoslik',
    'Mevachilik va sabzavotchilik',
    'Аholi tomorqalaridan samarali foydalanish',
    'Ingliz tili',
    'Nemis va frantsuz tillari',
    'Gumanitar yoʼnalishlar boʼyicha chet tillari',
    'Tabiiy yoʼnalishlar boʼyicha chet tillari',
    'Аmaliy ingliz tili',
    'Ingliz tili oʼqitish metodikasi',
    'Tilshunoslik',
    'Аdabiyotshunoslik',
    'Oʼzbek tili va adabiyoti',
    'Rus filologiyasi',
    'Rus tili metodikasi',
    'Jahon tarixi',
    'Oʼzbekiston tarixi',
    'Sotsiologiya',
    'Falsafa',
    'Ijtimoiy ish',
    'Huquq ta’limi',
    'Pedagogika',
    'Psixologiya',
    'Tasviriy sanʼat',
    'Musiqiy taʼlim va madaniyat',
    'Vokal va cholgʼu ijrochiligi',
    'Boshlangʼich taʼlim uslubiyoti',
    'Maktabgacha taʼlim',
    'Jismoniy madaniyat',
    'Jismoniy madaniyat nazariyasi va uslubiyoti',
    'Sport oʼyinlari',
    'Jahon va mintaqa iqtisodiyoti',
    'Iqtisodiyot va servis',
    'Moliya',
    'Buxgalteriya hisobi va iqtisodiy taxlil',
    'Harbiy taʼlim',
    'Аniq va tabiiy fanlar',
    'Ijtimoiy gumanitar fanlar',
  ],
};
//////////////////
