<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="!loading" [@fadeInAndOut]>
    <div class="card">
        <div class="card-header">
            <h5>Topshiriqlar</h5>
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <ng2-smart-table
                        [settings]="settings"
                        [source]="dataSource"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="loading">
    <div class="lds-dual-ring"></div>
</div>
<div class="kafdep-works-fixed" *ngIf="kafdep_send_work">
    <ion-content>
        <app-create [event_data]="kafdep_send_data" (close_event)="close_create_event($event)"></app-create>
        <!-- <app-tutor-works [tutor_data]="show_tutor_data" (close_tutor_work)="kafdep_send_work = false"></app-tutor-works> -->
    </ion-content>
</div>
<!-- Container-fluid Ends-->
