import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class CreateStudentComponent implements OnInit {
  // progress: number = 0;

  constructor(
      private httpService: HttpService,
      private authService: AuthService,
      private fb: FormBuilder,
      private formBuilder: FormBuilder,
      private config: NgbDatepickerConfig
    ) {
    this.createRegisterForm();
    const current = new Date();
    config.minDate = { year: 1950, month:
        1, day: 1 };
    config.maxDate = { year: 2010, month:
        11, day: 30 };
    //config.maxDate = { year: 2099, month: 12, day: 31 };
    // config.outsideDays = 'hidden';
  }

  tmp = {
    error: '',
    loading: false,
    fileError: '',
    progress: 0,
    progressOverAll: 0,
    loadingFile: false,
  };
  faculty;
  loading = false;
  files: File[] = [];
  public addStudent: FormGroup;
  groups: FormArray;
  uploadFileUrl = [];

  onSelectFile(event) {
      this.tmp.fileError = '';
      if (event.rejectedFiles[0]) {
        if (event.rejectedFiles[0].reason === 'type') {
           this.tmp.fileError = 'Fayl turlarini yuklash mumkin: jpeg, png, jpg, doc, pdf';
           return;
        }
        if (event.rejectedFiles[0].reason === 'size') {
           this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
           return;
        }
      }
      this.files.push(...event.addedFiles);
  }
  onRemoveFile(event) {
      this.files.splice(this.files.indexOf(event), 1);
  }
  ngOnInit() {
    this.tmp.progressOverAll = 0;
    this.faculty = environment.fakultet;
  }
  uploadFiles(nth){
    if (!this.files.length){this.add(); return; }
    this.tmp.loadingFile = true;
    const formData = new FormData();
    formData.append('avatar', this.files[nth]);
    this.httpService.uploadFile(formData)
    .subscribe((event) => {
      switch (event.type) {
        case HttpEventType.Sent:
          // console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          // console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          let eventTotal = event.total ? event.total : 0;
          this.tmp.progress = (Math.round(event.loaded / eventTotal * 100)) / this.files.length;
          // console.log(`Uploaded! ${this.tmp.progress}%`);
          break;
        case HttpEventType.Response:
          this.uploadFileUrl.push(event.body.url);
          if ((nth + 1) === this.files.length){this.add(); }
          else{this.uploadFiles( nth + 1 ); }
          this.tmp.progressOverAll += (this.tmp.progress);
          this.tmp.progress = 0;
      }
    });
  }
  createAnnounce(){
  }

  async add() {
    this.addStudent.patchValue({uid: this.authService.user_data.id});
    this.addStudent.value.files = this.uploadFileUrl;
    // console.log('addStudent e', this.addStudent.value, this.uploadFileUrl);
    this.tmp.loadingFile = false;
    this.tmp.loading = true;
    console.log(this.authService.user_data.id);
    this.httpService.addStudent(this.addStudent.value)
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        // console.log(result)
        alert('Talaba muvaffaqiyatli qo‘shildi!');

        this.files = [];
        this.uploadFileUrl = [];
        this.addStudent.reset();
        this.tmp.loading = false;
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }



  createRegisterForm() {
    this.addStudent = this.fb.group({
      last_name: ['', Validators.required],
      first_name: ['', Validators.required],
      patronymic: [''],
      faculty: ['', Validators.required],
      course: ['', Validators.required],
      group: ['', Validators.required],
      passport_series: ['', Validators.required],
      passport_given_by: ['', Validators.required],
      date_of_birth: ['', Validators.required],
      permanent_address_region: ['', Validators.required],
      permanent_address_district: ['', Validators.required],
      permanent_address_street: ['', Validators.required],
      current_address_ttj: [''],
      current_address_ttj_room: [''],
      rental_address: [''],
      phone: [''],
      fathers_phone: [''],
      mothers_phone: [''],
      inf_about_father: [''],
      inf_about_mother: [''],
      mfy_chairman: [''],
      mfy_chairman_supervisor: [''],
      disadvantages: [''],
      files: new FormArray([]),
      uid: ['']
    });
  }
}
