import { Component, OnInit } from '@angular/core';
import { menuDB } from 'src/app/shared/tables/menu';
import { HttpService } from 'src/app/shared/service/http.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DataService } from 'src/app/shared/service/data.service';
import { DatePipe } from '@angular/common';
import { KafdepCompletedComponent } from './kafdep-completed.component';
import { CompletedButtonViewComponent } from './completed-button-view/completed-button-view.component';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
interface tmp{
  loading: boolean;
}
@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class CompletedComponent implements OnInit {

  public announces_list = [];
  show_announce_answers = false;
  announce_data;
  loading = true;
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
    this.loading = true;
    this.getAnnounces();
  }

  public settings = {
    delete: {
      confirmDelete: true,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {
      id: {
        title: 'Id',
      },
      title: {
        title: 'Sarlavha',
      },
      context: {
        title: 'Mazmuni'
      },
      createdAt: {
        title: 'Sana',
      },
      files: {
        title: 'Fayllar',
        type: 'html'
      },
      // kafdep: {
      //   title: 'Kafedra va bo‘lim',
      //   type: 'custom',
      //   renderComponent: KafdepCompletedComponent
      // },
      answers: {
        title: 'Javoblar',
        type: 'custom',
        renderComponent: CompletedButtonViewComponent,
        onComponentInitFunction: (instance) => {
          instance.save.subscribe(rowData => {
            this.announce_data = rowData;
            this.show_announce_answers = true;
          });
        }
      }
    },
  };
  ngOnInit(){

  }
  getAnnounces(){
    this.httpService.getCompletedRequests()
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        this.genTable(result.completed_requests);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
  genTable(announces){
        announces.sort((a: any, b: any) => {
          return (b.time - a.time) ? (b.time - a.time) : (a.time - b.time);
        });
        announces.forEach(res => {
          let tutor;
          if (
            (tutor = res.tutors.find(t => {
              if (t.uid === this.authService.user_data.uid){return t; }else{return 0; }
            }))) {
            let a;
            if (res.files.length) {
              // res.files = 'fayllar';
              a = '<div class="file_download">';
              res.files.forEach((fet, indexFile) => {
                a += `<a href="${fet}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`;
              });
              a += '</div>';
              res.files = a;
            }
            res.createdAt = this.datePipe.transform(new Date(res.time * 1000), 'dd/MM/yyyy hh:mm');
            this.announces_list.push(res);
          }
        });
        this.loading = false;
  }
  onDeleteConfirm(event){
    this.httpService.removeRequest(event.data.time)
    .subscribe((result: any) => {
      if (result.status === 200) {
        alert('Topshiriq muvaffaqiyatli o‘chirildi!');
        event.confirm.resolve();
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
}
