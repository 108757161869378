<!-- Container-fluid starts-->
<div class="container-fluid" *ngIf="!loading" [@fadeInAndOut]>
    <div class="card">
        <div class="card-header">
            <h5>Topshiriqlar</h5>
        </div>
        <div class="card-body">
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="table-responsive">
                    <ng2-smart-table
                        [settings]="settings"
                        [source]="announces_list"
                        (deleteConfirm)="onDeleteConfirm($event)"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="lds-wrap" *ngIf="loading">
    <div class="lds-dual-ring"></div>
</div>
<div class="show_announce_answers" *ngIf="show_announce_answers">
  <ion-content>
    <app-checked-responses [show_only_one_announce_data]="announce_data" (close_announce_answer)="show_announce_answers = false"></app-checked-responses>
    <!-- <app-tutor-works [tutor_data]="show_tutor_data" (close_tutor_work)="kafdep_send_work = false"></app-tutor-works> -->
  </ion-content>
</div>
<!-- Container-fluid Ends-->
