import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';
import { Router } from '@angular/router'
import { AuthService } from 'src/app/shared/service/auth.service';

@Component({
  selector: 'list-button-view',
  template: `
    <div class="d-flex flex-nowrap text-white center">
      <div *ngFor="let t of rowData.tutors">
        <div *ngIf="t.uid === authService.user_data.uid">
          <button *ngIf="t.status === 'start' || t.status === 'rejected'" type="button" title="Topshiriqgaga javob jo‘natish!" class="btn btn-info" (click)="navigateToTutorWorks()">
            <i class="fa fa-arrow-right"></i>
          </button>
          <i *ngIf="t.status === 'pending'" title="Topshiriqgaga javob bergansiz va Tutor bo‘limi tomonidan tekshirilishi kutilmoqda" class="fa fa-hourglass-start pending" aria-hidden="true"></i>
          <i *ngIf="t.status === 'completed'" title="Ma'lumot jo‘natilgan va Tutor bo‘limi tomonidan qabul qilingan" class="fa fa-check green" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .center{
      justify-content:center;
    }
    .pending{
      color: #000000;
      font-size: 20px;
    }
    .green{
      color: #3ceb3c;
      font-size: 20px;
    }
    .btn{
      padding: 3px 0 0 0px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  `]
})
export class ButtonViewComponent implements ViewCell, OnInit {

  @Input() value: string | number;
  @Input() rowData: any;
  // @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  constructor(
    private router: Router,
    public authService: AuthService,
){}

  ngOnInit() {
    console.log('rowData', this.rowData);
  }

  navigateToTutorWorks() {
    this.save.emit(this.rowData);
    // console.log(this.rowData)
    // this.router.navigate(['tutor-works/'+this.rowData.uid]);
    //   const url = this.router.serializeUrl(
    //   this.router.createUrlTree([`/tutor-works/${this.rowData.uid}`])
    // );

    // window.open(url, '_blank');
  }
}
