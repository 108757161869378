import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../../../shared/service/data.service';
import { HttpService } from '../../../shared/service/http.service';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class CreateUserComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  tizim;
  tmp = {error: '', loading: false};
  pvoKaf;
  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private httpService: HttpService,
  ) {
    this.createAccountForm();
    this.createPermissionForm();
    this.tizim = dataService.tizim;
    dataService.pvoKaf$.subscribe(res => {
      this.pvoKaf = res;
    });
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      patronymic: [''],
      born: ['', Validators.required],
      rank: ['', Validators.required],
      wagerate: ['', Validators.required],
      academic_degree: [''],
      academic_title: [''],
      state_type: ['', Validators.required],
      kafed: ['', Validators.required],
      fakul: ['', Validators.required],
    });
  }
  tanKafedra(){
    return (this.tizim.filter(res => res.fakultet === this.accountForm.value.fakul))[0];
  }
  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({
    });
  }
  ngOnInit() {
  }
  get f(){
    return this.accountForm.controls;
  }
  onAdd(event){
    let value = this.accountForm.value;
    this.tmp.loading = true;
    if (this.pvoKaf.find(res => ((res.fname)?.replace(/[ ,.]/g, '') === (value.fname)?.replace(/[ ,.]/g, '')) && ((res.lname)?.replace(/[ ,.]/g, '') === (value.lname)?.replace(/[ ,.]/g, '')) && ((res.patronymic ? res.patronymic : '').replace(/[ ,.]/g, '') === (value.patronymic ? value.patronymic : '').replace(/[ ,.]/g, '')))) {
      this.tmp.loading = false;
      return this.tmp.error = 'Bunday ism va sharifli o\'qituvchi mavjud!';
    }
    this.httpService.setNewPvo(value)
      .subscribe((result: any) => {
        // console.log('result', result);
        if (result.status === 200) {
          this.accountForm.reset();
          this.tmp.loading = false;
          alert('Ma\'lumotlar muvaffaqiyatli saqlandi!');
          this.tmp.error = '';
        }else{
          alert('Nimadur hato ketti!');
        }
      }, error => {
        console.error(error);
      });
  }
}
