import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { DataService } from 'src/app/shared/service/data.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalDataSource } from 'ng2-smart-table';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-checked-responses',
  templateUrl: './checked-responses.component.html',
  styleUrls: ['./checked-responses.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
  providers: [DatePipe]
})
export class CheckedResponsesComponent implements OnInit {

  @Input() show_only_one_announce_data: any;
  @Output() close_announce_answer = new EventEmitter();

  @Input() tutor_data: any;
  @Output() close_tutor_work = new EventEmitter();

  public announces_list = [];
  dataSource: LocalDataSource = new LocalDataSource();
  pager = 10;
  loading = true;
  constructor(
    private dataService: DataService,
    private httpService: HttpService,
    // private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {}

  public settings = {
    delete: {
      confirmDelete: false,
      deleteButtonContent: '<a class="table_del_default_icon"></a>'
    },
    actions: {
      add: false,
      edit: false,
      delete: false,
      position: 'right'
    },
    columns: {

      id: {
        title: 'Id',
      },
      title: {
        title: 'Sarlavha',
      },
      context: {
        title: 'Mazmuni',
      },
      timeShow: {
        title: 'Sana',
      },
      files: {
        title: 'Fayllar',
        type: 'html'
      },
    },
  };
  ngOnInit(){
    this.getAnnounces();
    this.loading = true;

  }

  getAnnounces(){
    this.httpService.getAcceptedResponses(this.show_only_one_announce_data.tutors)
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        this.genTable(result.responses);
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }
  genTable(announces){
        announces.forEach((res, idx) => {

            let a;
            if (res.files && res.files.length){
              a = '<div class="file_download">';
              res.files.forEach((fet, indexFile) => {
                a += `<a href="${fet}" target="_blank" class="file_download_link"><img src='assets/images/icon/file-icon.png'></a>`;
              });
              a += '</div>';
              res.files = a;
            }
            res.timeShow = this.datePipe.transform( new Date(res.time * 1000) , 'dd/MM/yyyy hh:mm');
            this.dataSource['data'].push(res);

        });
        this.dataSource.refresh();
        this.loading = false;
  }
}
