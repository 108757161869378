import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import { HttpService } from 'src/app/shared/service/http.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { NgbDateStruct, NgbDate, NgbCalendar, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { environment } from 'src/environments/environment.prod';
import {
  trigger,
  transition,
  useAnimation
} from '@angular/animations';

import { slideFadeOut, useSlideFadeInAnimation } from 'src/app/shared/animations/animations';
import { fadeInAndOut } from 'src/app/shared/animations/triggers';
@Component({
  selector: 'app-extend-living',
  templateUrl: './extend-living.component.html',
  styleUrls: ['./extend-living.component.scss'],
  animations: [
    // The following are pre-built triggers - Use these to add animations with the least work
    fadeInAndOut,

    // The following is a custom trigger using animations from the package
    // Use this approach if you need to customize the animation or use your own states
    trigger('enterFromLeftLeaveToRight', [
      // this transition uses a function that returns an animation with custom parameters
      transition(':enter', useSlideFadeInAnimation('300ms', '20px')),
      // This transition uses useAnimation and passes the parameters directly - accomplishing the same thing as the above function
      transition(':leave', useAnimation(slideFadeOut, {params: {time: '2000ms', endPos: '100px'}})),
    ]),
  ],
})
export class ExtendLivingComponent implements OnInit {
  // progress: number = 0;
  // @Input() inputRoomRowData;
  @Input() inputExtendLivingRowData;
  @Output() closeExtendLiving = new EventEmitter();

  constructor(
      private httpService: HttpService,
      private authService: AuthService,
      private fb: FormBuilder,
      private formBuilder: FormBuilder,
      private config: NgbDatepickerConfig
    ) {
    this.createRegisterForm();
    const currentYear = new Date().getFullYear();
    config.minDate = { year: currentYear, month:
        9, day: 1 };
    config.maxDate = { year: currentYear + 1, month:
        8, day: 30 };
    // config.outsideDays = 'hidden';
  }

  tmp = {
    error: '',
    loading: false,
    fileError: '',
    progress: 0,
    progressOverAll: 0,
    loadingFile: false,
  };
  loading = false;
  files: File[] = [];
  public extendLiving: FormGroup;
  groups: FormArray;
  uploadFileUrl = [];

  onSelectFile(event) {
      this.tmp.fileError = '';
      if (event.rejectedFiles[0]) {
        if (event.rejectedFiles[0].reason === 'type') {
           this.tmp.fileError = 'Fayl turlarini yuklash mumkin: jpeg, png, jpg, doc, pdf';
           return;
        }
        if (event.rejectedFiles[0].reason === 'size') {
           this.tmp.fileError = '20mb gacha fayl yuklash mumkin';
           return;
        }
      }
      this.files.push(...event.addedFiles);
  }
  onRemoveFile(event) {
      this.files.splice(this.files.indexOf(event), 1);
  }
  ngOnInit() {
    this.tmp.progressOverAll = 0;
  }
  uploadFiles(nth){
    if (!this.files.length){this.extendLivingFunc(); return; }
    this.tmp.loadingFile = true;
    const formData = new FormData();
    formData.append('avatar', this.files[nth]);
    this.httpService.uploadFile(formData)
    .subscribe((event) => {
      switch (event.type) {
        case HttpEventType.Sent:
          // console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          // console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          let eventTotal = event.total ? event.total : 0;
          this.tmp.progress = (Math.round(event.loaded / eventTotal * 100)) / this.files.length;
          // console.log(`Uploaded! ${this.tmp.progress}%`);
          break;
        case HttpEventType.Response:
          this.uploadFileUrl.push(event.body.url);
          if ((nth + 1) === this.files.length){this.extendLivingFunc(); }
          else{this.uploadFiles( nth + 1 ); }
          this.tmp.progressOverAll += (this.tmp.progress);
          this.tmp.progress = 0;
      }
    });
  }


  async extendLivingFunc() {
    this.extendLiving.value.files = this.uploadFileUrl;
    const data = {
      tutor_uid: this.inputExtendLivingRowData.uid,
      accommodating_student_id: this.inputExtendLivingRowData.time,
      new_period_data: this.extendLiving.value
    };
    this.tmp.loadingFile = false;

    this.tmp.loading = true;
    this.httpService.extendLivingDormitory(data)
    .subscribe(async (result: any) => {
      if (result.status === 200) {
        this.files = [];
        this.uploadFileUrl = [];
        this.extendLiving.reset();
        this.tmp.loading = false;
        this.closeExtendLiving.emit({status: 'success', data});
      }else{
        alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      }
    }, error => {
      alert('Nimadur hato ketti yana bir bor urinib ko\'ring!');
      console.error(error);
    });
  }



  createRegisterForm() {
    this.extendLiving = this.fb.group({
      start_date: ['', Validators.required],
      finish_date: ['', Validators.required],
      files: new FormArray([]),
    });
  }
}
