import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NavService } from 'src/app/shared/service/nav.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { saveAs } from 'file-saver';
import { environment } from 'src/environments/environment.prod';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar = false;
  public open = false;
  public openNav = false;
  public isOpenMobile: boolean;
  user;
  otm_name = environment.otm_name;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(public navServices: NavService,
              public authService: AuthService,
              private router: Router
    ) {
    // this.authService.user.subscribe(user=>{
    //   this.user = user;
    // })
  }
  segmentChanged(event){
    switch (event.detail.value) {
      case 'statistics':
        this.router.navigate(['/statistics']);
        break;
      case 'list-students-all':
        this.router.navigate(['/a-students/list-students']);
        break;
      case 'tutor-works-all':
        this.router.navigate(['tutor-works/all']);
        break;
      case 'admin-requests':
        this.router.navigate(['admin-requests/create']);
        break;
      case 'checking-requests':
        this.router.navigate(['admin-requests/list']);
        break;
      case 'done-requests':
        this.router.navigate(['admin-requests/completed']);
        break;
      case 'request-response':
        this.router.navigate(['request-response']);
        break;
      case 'request-response-done':
        this.router.navigate(['request-response/completed']);
        break;
      case 'announce':
        this.router.navigate(['tutor-admin']);
        break;
      case 'new-user':
        this.router.navigate(['/users/register-new']);
        break;
      case 'list-user':
        this.router.navigate(['/users/list-user']);
        break;
      case 'my-event-pvo':
        this.router.navigate(['/tutor-event/list-event']);
        break;
      case 'add-event':
        this.router.navigate(['/tutor-event/create-event']);
        break;
      case 'announce-pvo':
        this.router.navigate(['/announce']);
        break;
      case 'works-done':
        this.router.navigate(['/tutor-works/all']);
        break;
      case 'new-student':
        this.router.navigate(['/students/create-student']);
        break;
      case 'list-students':
        this.router.navigate(['/students/list-students']);
        break;
      case 'add-room':
        this.router.navigate(['/dormitory-room/create']);
        break;
      case 'rooms-list':
        this.router.navigate(['/dormitory-room/list']);
        break;
      case 'accommodated-students-list':
        this.router.navigate(['/accommodated-students/list-students']);
        break;
      default:

        // code...
        break;
    }
  }
  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  signOut(){
    this.authService.signOut();
  }

  ngOnInit() {  }
  backUpDb(i){
    const baza = ['checkfield', 'fieldData', 'fieldsInform', 'fieldsRejected', 'lastId', 'pvoIns'];
    // if (i<baza.length) {
    //   this.firebabeService.getDoc(baza[i]).then(snapshot=>{
    //     if (snapshot.exists()) {
    //       console.log('snapshot ', snapshot.val());
    //       const blob = new Blob([JSON.stringify(snapshot.val())], {type: "text/plain;charset=utf-8"});
    //       saveAs(blob, `${baza[i]}.txt`);
    //       setTimeout(()=>{
    //         this.backUpDb(i+1);
    //       }, 2000)
    //     }
    //   }).catch(error=>{
    //     alert('#536 xato ketti ' + error);
    //   })
    // }
  }
}
