import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TutorAdminRoutingModule } from './tutor-admin-routing.module';
import { TutorAdminComponent } from './tutor-admin.component';
// import { AnnounceComponent } from '../announce/announce.component';
import { SharedModule } from '../../shared/shared.module';
import { ListAnnounceComponent } from '../announce-admin/list-announce/list-announce.component';
import { CreateAnnounceComponent } from '../announce-admin/create-announce/create-announce.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { FormsModule } from '@angular/forms';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TutorAdminComponent, 
    ListAnnounceComponent, 
    CreateAnnounceComponent
  ],
  imports: [
    CommonModule,
    TutorAdminRoutingModule,
    SharedModule,
    Ng2SmartTableModule,
    ReactiveFormsModule,
    NgbModule,
    NgxDropzoneModule,
    NgCircleProgressModule,
    FormsModule
  ],
  providers: [NgbActiveModal],
})
export class TutorAdminModule { }
